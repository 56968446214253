import Banner from '../../components/Banner/index.jsx'
import Caroussel from '../../components/Carousel/index.jsx'
import Navbar from '../../components/Navbar/index.jsx'
import service01 from '../../assets/home/service01.webp'
import service02 from '../../assets/home/service02.webp'
import service03 from '../../assets/home/service03.webp'
import service04 from '../../assets/home/service04.webp'
import aboutUs from '../../assets/home/about-uss.jpg'
import { FaRegEye } from 'react-icons/fa'
import Contact from '../../components/Contact/index.jsx'
import NavbarLink from '../../components/NavbarLink/NavbarLink.jsx'
import { products } from '../../fakers'
import { Link } from 'react-router-dom'
import Popups from '../../components/Popups/Popups.jsx'
import { useState } from 'react'
// import Modal from '../../components/Modal/Modal.jsx'
import ModalUnstyled from '../../components/Modal/ModalUI.jsx'
import useCountries from '../../hooks/useCountries.js'
import useSendMail from '../../hooks/useSendMail.js'
// import SlideSlick from '../../components/Slide/SlideSlick.jsx'

function Home() {
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModaData] = useState('')

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const { allCountries } = useCountries()

  const { formik, loading, country, handleContryChange } = useSendMail()

  return (
    <>
      <div>
        <Banner />
        <Popups />
        <Caroussel />
        <div className='content'>
          <NavbarLink position='sticky top-0' />
          <Navbar
            allCountries={allCountries}
            formik={formik}
            loading={loading}
            country={country}
            handleContryChange={handleContryChange}
          />
          <div className='text-center'>
            {/* Ma 1ere section */}
            <div className='px-[15px] pb-[40px] md:p-20'>
              <h1 className='text-[20px] leading-[30px] mt-[20px] mb-[30px]  md:text-[36px] font-medium text-gray-800 md:mb-7'>
                Global Wood Market SA, Wholesaler and Supplier
              </h1>
              <p className='text-sm text-gray-500 leading-6 mb-2'>
                "Global Wood Market SA" is the top exporter of quality hardwood
                including Oak, Aish and other wood logs. Based at Douala,
                Cameroon, we supply premium quality wood logs, timber and lumber
                wood to be used for every purpose and with delivery to multiple
                locations around the world. One of the{' '}
                <b>best timber logs suppliers</b> in the area, we at Global Wood
                Market specialize in the finishing, trading and export of a wide
                range of popular domestic and commercial purpose wooden timber
                logs.
              </p>
              <p className='text-sm text-gray-500 leading-6 mb-8'>
                Whether you need rough wood logs or finished timber/lumber, we
                can deliver it to the top locations in the world. Regarded as
                one of the best timber companies in Cameroon, we are committed
                to serving our clients with the best quality timber for any
                requirement. Presently, we are exporting our timber to various
                locations in the Middle East and Asia, including Iraq, Malaysia,
                Vietnam, and all the GCC Countries - Saudi Arabia, Kuwait, the
                United Arab Emirates, Qatar, Bahrain, and Oman. Apart from that,
                we also provide <b>wood export services</b> in most parts of
                Europe. So, if you are based in any of these countries and
                looking for quality wood timber, contact us now to discuss your
                needs.
              </p>
              <p className='text-sm text-gray-500 leading-6 mb-3'>
                Looking to{' '}
                <a href=' ' alt='image'>
                  <b>buy timber online</b>
                </a>
                ? Well, your search ends here. <b>Global Wood Market SA</b> are
                here to serve all your requirements of quality wooden logs and
                timber. We deal in multiple varieties of wood species such as
                Tali, Padauk, Mahogany, Teak and many more. We can also offer
                customized solutions if you need any specific kind of wood. At
                Global Wood Market, you will get quality products and services
                with timely delivery andhome/ satisfaction guarantee. You can
                also order our timber for home delivery in many countries and
                locations across Africa, North America, South America, and Asia.
                We are exporting our wood to Turkey, the Philippines, Australia,
                New Zealand, South Africa, Kenya, Morocco, Angola, Canada,
                Guatemala, Panama, Brazil, Columbia, Ecuador, Honduras, Costa
                Rica, Italy, the UK, Finland, Germany, Austria, Poland, Sweden,
                Pakistan, Norway, Belgium, Ireland, Singapore, Ghana, Bulgaria,
                Estonia and several more location around the globe. If you’re in
                any of these countries, feel free to visit our website to
                explore our wide range of timber across hundreds of hardwood and
                softwood species like Alder, Hickory, Birch, Cherry, Redwood,
                Acacia, Rubberwood, bamboo, basswood, bloodwood, Bocote, Buloke
                wood, chestnut wood, Elmwood, Hemlock, Larch, Locust, Madrone,
                Purpleheart wood, Quebracho, Sheoak wood, Tulipwood, willow
                wood, Yew, Ziricote, and many more.
              </p>
              <p className='text-sm text-gray-500 leading-6'>
                Check out our portfolio to know more about our business and the
                types of wood we export.
              </p>
            </div>

            {/* Ma 2e section */}
            <div className='px-[15px] md:p-20'>
              <h2 className='text-[18px] leading-[30px] mt-[20px] mb-[30px]  md:text-[36px] font-medium text-gray-800 md:mb-7'>
                Why Choose Global Wood Market SA?
              </h2>
              <p className='text-sm text-gray-500 leading-6 mb-8'>
                Global Wood Market SA delivers top quality African wood species
                locations around the world. Our service quality lies in our
                years of experience and in-depth understanding of the wood. Hire
                our services to get the best-class wooden logs and timber with
                timely delivery.
              </p>
              <div className='grid grid-cols-1 gap-y-16  md:grid-cols-4 md:gap-y-0 md:gap-x-5'>
                <div className='text-center'>
                  <img
                    src={service01}
                    alt=''
                    className='m-auto duration-500 hover:scale-110'
                  />
                  <h3 className='mt-7 text-gray-900 text-lg font-medium'>
                    Cordial Terms of Service
                  </h3>
                  <p className='text-sm text-gray-500 leading-5 mt-2'>
                    User-friendly terms of service designed to fit various
                    business needs of different types of buyers
                  </p>
                </div>
                <div className='text-center'>
                  <img
                    src={service02}
                    alt=''
                    className='m-auto duration-500 hover:scale-110'
                  />
                  <h3 className='mt-7 text-gray-900 text-lg font-medium'>
                    Effecient and Timely Delivery
                  </h3>
                  <p className='text-sm text-gray-500 leading-5 mt-2'>
                    We are famous for quality wooden products and efficient
                    service with guaranteed timely delivery
                  </p>
                </div>
                <div className='text-center'>
                  <img
                    src={service03}
                    alt=''
                    className='m-auto duration-500 hover:scale-110'
                  />
                  <h3 className='mt-7 text-gray-900 text-lg font-medium'>
                    24/7 Live Support
                  </h3>
                  <p className='text-sm text-gray-500 leading-5 mt-2'>
                    Reach our expert support team at any time to ask your
                    questions or get your issues resolved
                  </p>
                </div>
                <div className='text-center'>
                  <img
                    src={service04}
                    alt=''
                    className='m-auto duration-500 hover:scale-110'
                  />
                  <h3 className='mt-7 text-gray-900 text-lg font-medium'>
                    Quality Logs for Every Project
                  </h3>
                  <p className='text-sm text-gray-500 leading-5 mt-2'>
                    At Global Wood Market, we deliver high-quality logs for
                    every kind of project and for every personal and business
                    use.
                  </p>
                </div>
              </div>
            </div>

            {/* Ma 3e section */}
            <div className='bg-visian-bg mt-24 flex flex-col md:flex-row px-[15px] py-[60px] md:px-20 md:py-20 md:gap-x-7'>
              <div className='text-white text-center md:text-start '>
                <h2 className='text-[18px] sm:w-1/2 md:w-auto md:text-3xl font-medium pb-5'>
                  Our History & Vision
                </h2>
                <p className='text-sm mb-3'>
                  Incorporated in the year 1999 at Douala (Cameroon), we “Global
                  Wood Market SA” are a Proprietorship Firm indulged in
                  wholesaling, retailing and trading optimum quality Timber of
                  Central African Species such as Tali, padauk, Sapele
                  (Mahogany), Iroko, Teak, Bubinga, Pachyloba etc.
                </p>
                <p className='text-sm mb-3'>
                  We have also partnered with the Ministry Of Forestry and
                  Wildlife in Douala City to promote research and cooperation
                  and to keep our manufacturing capabilities and production
                  personnel updated with the latest technologies and
                  developments.
                </p>
                <p className='text-sm mb-3'>
                  With years of industry experience, we offer a wide range of
                  wood species and export services, catering to each buyer’s
                  request. “WE ARE HERE BECAUSE YOU ARE THERE“.
                </p>
                <p className='text-sm'>
                  Our vision at Global Wood Market SA is to become one of the
                  top timber companies in Cameroon and to keep providing the
                  best quality timber export services to our clients across the
                  world.{' '}
                </p>
              </div>
              <img
                src={aboutUs}
                alt=''
                className='m-auto sm:max-w-[50%] sm:h-[50vh] md:h-[60vh] lg:h-auto lg:max-w-[100%] mt-[60px] md:mt-0'
              />
            </div>
            <div className='px-[15px] pb-[50px] md:p-16'>
              <h2 className='text-[18px] leading-[30px] mt-[20px] mb-[30px]  md:text-[36px] font-medium text-gray-800 md:mb-7'>
                Types of Wood In Global Wood Market SA
              </h2>
              <p className='text-sm text-gray-500 leading-6 text-center  md:px-32'>
                We trade and export all the popular types of Wood In Cameroon in
                both round log and timber forms. Our wood products include but
                are not limited to Padauk logs, padauk timber, Okan wood, Pachy,
                Pine wood and Teak lumber, among others.
              </p>
            </div>
            <div className='px-[15px] my-[200px] md:my-0 md:px-5'>
              <h2 className='text-[18px] leading-[30px] mt-[20px] mb-[30px]  md:text-[36px] font-medium text-gray-800 md:mb-7'>
                African Round Log Species
              </h2>
              <div className='grid grid-cols-1 min-[450px]:grid-cols-2 gap-3 px-10 md:grid-cols-3 lg:grid-cols-4 md:px-24 md:gap-10'>
                {products?.slice(0, 8)?.map((item, index) => (
                  <div
                    key={index}
                    className='border bg-[#4a1f0e] p-3 text-white'
                  >
                    <img
                      src={item.images[2]}
                      alt=''
                      className='mb-4 duration-500 hover:scale-110'
                    />
                    <div
                      onClick={() => {
                        openModal()
                        setModaData(item)
                      }}
                      className='cursor-pointer flex w-32 py-1 px-2 mb-[10px] text-[14px] justify-center items-center m-auto border'
                    >
                      <FaRegEye />
                      <p className='pl-2'>Quick View</p>
                    </div>
                    <p className='font-semibold text-[16px] md:text-[14px] py-[8px] min-h-[56px]'>
                      {item.name}
                    </p>
                    <Link
                      to={`/product/${item.url}`}
                      state={item}
                      className='border w-28 m-auto text-[14px] mt-[14px] py-[4px] px-[10px] rounded-sm bg-[#ebe9eb] text-[#515151] font-bold '
                    >
                      Read more
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className='px-[15px] pt-20 my-[200px] md:my-0 md:px-5'>
              <h2 className='text-[18px] leading-[30px] mt-[20px] mb-[30px]  md:text-[36px] font-medium text-gray-800 md:mb-7'>
                African Sawn Timber
              </h2>
              <div className='grid grid-cols-1 min-[450px]:grid-cols-2 gap-3 px-10 md:grid-cols-3  lg:grid-cols-4 md:px-24 md:gap-10'>
                {products
                  ?.filter(
                    (category) => category.category === 'African Sawn Timber'
                  )
                  ?.slice(0, 8)
                  ?.map((item, index) => (
                    <div
                      key={index}
                      className='border bg-[#4a1f0e] p-3 text-white'
                    >
                      <img
                        src={item.images[2]}
                        alt=''
                        className='mb-4 duration-500 hover:scale-110'
                      />
                      <div
                        onClick={() => {
                          openModal()
                          setModaData(item)
                        }}
                        className='cursor-pointer flex w-32 py-1 px-2 mb-[10px] text-[14px] justify-center items-center m-auto border'
                      >
                        <FaRegEye />
                        <p className='pl-2 pt-1'>Quick View</p>
                      </div>
                      <p className='font-semibold text-[16px] md:text-[14px] py-[8px] min-h-[56px]'>
                        {item.name}
                      </p>
                      <Link
                        to={`/product/${item.url}`}
                        state={item}
                        className='border w-28 m-auto text-[14px] mt-[14px] py-[4px] px-[10px] rounded-sm bg-[#ebe9eb] text-[#515151] font-bold '
                      >
                        Read more
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <Contact
            allCountries={allCountries}
            formik={formik}
            loading={loading}
            country={country}
            handleContryChange={handleContryChange}
          />
        </div>
      </div>
      {/* <SlideSlick /> */}
      <ModalUnstyled
        open={showModal}
        handleClose={closeModal}
        item={modalData}
      />
    </>
  )
}

export default Home
