import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Contactus from './pages/Contactus'
import ProductDetail from './pages/ProductDetail'
import ProductCategory from './pages/ProductCategory/ProductCategory'
import Search from './pages/Search/index'
import './App.css'

const App = () => {
  // const [isAllowed, setIsAllowed] = useState(false)

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition)
  //   } else {
  //     console.log(
  //       "La géolocalisation n'est pas prise en charge par ce navigateur."
  //     )
  //   }
  // }

  // const showPosition = (position) => {
  //   const latitude = position.coords.latitude
  //   const longitude = position.coords.longitude

  //   fetch(
  //     `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=fr`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const country = data.countryName

  //       if (country === 'Cameroun (le)' || country === 'Gabon (le)') {
  //         alert(
  //           "Désolé, l'accès à ce site depuis votre pays n'est pas autorisé."
  //         )
  //         window.location.href = 'https://www.example.com/blocked'
  //       } else {
  //         setIsAllowed(true)
  //       }
  //     })
  //     .catch((error) =>
  //       console.error(
  //         'Erreur lors de la récupération de la localisation : ',
  //         error
  //       )
  //     )
  // }

  // getLocation()

  return (
    <>
      {/* {isAllowed && ( */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contactus />} />
        <Route path='/product/:id' element={<ProductDetail />} />
        <Route path='/product-category/:id' element={<ProductCategory />} />
        <Route path='/search?' element={<Search />} />
      </Routes>
      {/* )} */}
    </>
  )
}

export default App

// useEffect(() => {
//   const getLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(showPosition)
//     } else {
//       console.log(
//         "La géolocalisation n'est pas prise en charge par ce navigateur."
//       )
//     }
//   }

//   const showPosition = (position) => {
//     const latitude = position.coords.latitude
//     const longitude = position.coords.longitude

//     fetch(
//       `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=fr`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         const country = data.countryName

//         if (country === 'Cameroun (le)' || country === 'Gabon (le)') {
//           alert(
//             "Désolé, l'accès à ce site depuis votre pays n'est pas autorisé."
//           )
//           // Fermer l'onglet après l'affichage du message d'erreur
//           window.location.href = 'https://www.example.com/blocked'
//         }
//       })
//       .catch((error) =>
//         console.error(
//           'Erreur lors de la récupération de la localisation : ',
//           error
//         )
//       )
//   }

//   getLocation()
// }, [])

//   return (
//     <Routes>
//       <Route path='/' element={<Home />} />
//       <Route path='/about' element={<About />} />
//       <Route path='/contact' element={<Contactus />} />
//       <Route path='/product/:id' element={<ProductDetail />} />
//       <Route path='/product-category/:id' element={<ProductCategory />} />
//       <Route path='/search?' element={<Search />} />
//     </Routes>
//   )
// }
