import React from 'react'
import AppSelect from '../Form/Select'
import { BeatLoader } from 'react-spinners'

const ProductDetailForm = ({
  allCountries,
  formik,
  loading,
  country,
  handleContryChange,
}) => {
  return (
    <form onSubmit={formik.handleSubmit} className='space-y-6'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
        <div>
          <input
            className='focus:outline-none bg-transparent border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] placeholder:font-light w-full'
            type='text'
            name='name'
            id='name'
            placeholder='Name'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <span className='text-red-500'>
            {formik.touched.name && formik.errors.name && formik.errors.name}
          </span>
        </div>
        <div>
          <input
            className='focus:outline-none bg-transparent border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] placeholder:font-light w-full'
            type='email'
            name='email'
            id='email'
            placeholder='Email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <span className='text-red-500'>
            {formik.touched.email && formik.errors.email && formik.errors.email}
          </span>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
        <div>
          <input
            className='focus:outline-none bg-transparent border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] placeholder:font-light w-full'
            type='text'
            name='telephone'
            id='telephone'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telephone}
            placeholder='Whatsapp No./Phone No.'
            required
          />
          <span className='text-red-500'>
            {formik.touched.telephone &&
              formik.errors.telephone &&
              formik.errors.telephone}
          </span>
        </div>
        <AppSelect
          value={country}
          handleChange={handleContryChange}
          options={allCountries}
          placeholder={'Select your country'}
        />
      </div>
      <div>
        <textarea
          className='input-primary p-2'
          name='message'
          id='message'
          rows={6}
          placeholder='Enter your message'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          required
        ></textarea>
        <span className='text-red-500'>
          {formik.touched.message &&
            formik.errors.message &&
            formik.errors.message}
        </span>
      </div>

      <button type='submit' className='btn-primary w-32'>
        {loading ? <BeatLoader loading={loading} color='#fff' /> : 'Send'}
      </button>
    </form>
  )
}

export default ProductDetailForm
