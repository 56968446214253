import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const ProductDetailCard = ({ name, img, item, url }) => {
  const navigate = useNavigate()
  return (
    <div className='border p-3 text-black'>
      <img
        src={img}
        alt=''
        className='mb-3 w-full h-auto object-cover duration-500 hover:scale-110'
      />
      <div className='flex flex-col justify-between items-center'>
        <Link to={`/product/${url}`} state={item}>
          <p className='font-semibold py-3 text-center text-primary'>{name}</p>
        </Link>
        <button
          onClick={() => {
            navigate(`/product/${url}`, {
              state: item,
            })
          }}
          className='btn-secondary py-1 px-3 rounded-sm'
        >
          Read more
        </button>
      </div>
    </div>
  )
}

export default ProductDetailCard
