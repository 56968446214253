import React from 'react'
import './index.css'
import Select from 'react-tailwindcss-select'
import { BeatLoader } from 'react-spinners'

const Navbar = ({
  allCountries,
  formik,
  loading,
  country,
  handleContryChange,
}) => {
  return (
    <>
      <div
        id='start'
        className='bg-[#f1f1f1] px-10 md:px-24 pt-10 pb-16 md:pb-24'
      >
        <h2 className='capitalize font-bold text-2xl text-gray-700 text-center mb-2'>
          contact global wood market today to know the wood price
        </h2>
        <p className='uppercase font-bold text-base text-gray-500 text-center mb-4'>
          +237695268508
        </p>
        <form
          // ref={form}
          onSubmit={formik.handleSubmit}
          action=''
          className='grid gap-5 grid-cols-1 md:grid-cols-3 text-[14px]'
        >
          <div className='w-full'>
            <input
              className='focus:outline-none border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] placeholder:font-semibold w-full'
              type='text'
              name='name'
              id='name'
              placeholder='Name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <span className='text-red-500'>
              {formik.touched.name && formik.errors.name && formik.errors.name}
            </span>
          </div>
          <div className='w-full'>
            <input
              className='focus:outline-none border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] placeholder:font-semibold w-full'
              type='email'
              name='email'
              id='email'
              placeholder='Email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <span className='text-red-500'>
              {formik.touched.email &&
                formik.errors.email &&
                formik.errors.email}
            </span>
          </div>
          <div className='w-full'>
            <input
              className='focus:outline-none border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] placeholder:font-semibold w-full'
              type='text'
              name='telephone'
              id='telephone'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telephone}
              placeholder='Whatsapp No./Phone No.'
              required
            />
            <span className='text-red-500'>
              {formik.touched.telephone &&
                formik.errors.telephone &&
                formik.errors.telephone}
            </span>
          </div>
          <Select
            classNames={{
              menuButton: () =>
                'focus:outline-none border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] flex justify-between text-sm text-gray-500 cursor-pointer items-center  shadow-sm transition-all duration-300 overflow-hidden bg-white placeholder:font-bold',
              list: 'max-h-28 pt-0.5 pb-2 overflow-y-scroll overflow-x-hidden',
              menu: 'absolute z-10 w-full bg-white shadow-lg border rounded-sm py-1 mt-0.5 text-sm text-gray-700',
              listItem: ({ isSelected }) =>
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded hover:bg-primary hover:text-white ${
                  isSelected
                    ? `text-white bg-primary`
                    : `text-gray-500 hover:bg-primary hover:text-white`
                }`,
            }}
            options={allCountries}
            value={country}
            onChange={handleContryChange}
            placeholder='Select your country'
            isSearchable
            isClearable
            name='Country'
          />
          <div className='w-full'>
            <textarea
              className='focus:outline-none border border-gray-300 rounded-md  h-[45px] py-[6px] px-[12px] placeholder:font-semibold w-full'
              name='message'
              id='message'
              value={formik.values.message}
              placeholder='Enter Your Message'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            <span className='text-red-500'>
              {formik.touched.message &&
                formik.errors.message &&
                formik.errors.message}
            </span>
          </div>
          <button
            type='submit'
            className='border-gray-300 text-[17px] rounded-md w-full h-[45px] bg-[#4e1904] text-white font-semibold uppercase cursor-pointer flex justify-center items-center'
          >
            {loading ? (
              <BeatLoader loading={loading} color='#fff' />
            ) : (
              'Send Now'
            )}
          </button>
        </form>
      </div>
    </>
  )
}

export default Navbar
