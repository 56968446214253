import React from 'react'
import Select from 'react-tailwindcss-select'

const AppSelect = (props) => {
  return (
    <div className='flex flex-col gap-2'>
      {/* <label>
        {props.label}{" "}
        {props.required && <span className="text-primary">*</span>}
      </label> */}
      <Select
        name={props.name}
        id={props.id}
        required
        isSearchable
        isClearable
        options={props.options}
        value={props.value}
        primaryColor='#4e1904'
        isMultiple={props.isMultiple ?? false}
        onChange={props.handleChange}
        placeholder={
          props.placeholder
          //   <FormattedMessage id="page.register.student.choose_campus" />
        }
        // searchInputPlaceholder={translate_input(
        //   "page.register.student.search_campus"
        // )}
        classNames={{
          menuButton: ({ isDisabled }) =>
            `flex justify-between text-sm ${
              props.textColor ? props.textColor : 'text-gray-500'
            } cursor-pointer items-center focus:outline-none border-[1.5px]  ${
              props.borderColor ? props.borderColor : 'border-gray-300'
            } h-12 rounded shadow-sm transition-all duration-300 focus:outline-none overflow-x-hidden  ${
              props.error
                ? 'bg-red-100 border-red-400 hover:bg-red-200 hover:border-red-700'
                : 'bg-transparent hover:bg-primary-100 '
            }`,
          // tagItemText: "border-4 border-black",
          tagItemText: '',
          list: 'max-h-28 pt-0.5 pb-2 overflow-y-scroll overflow-x-hidden',
          menu: 'absolute z-10 w-full bg-white shadow-lg border rounded-sm py-1 mt-0.5 text-sm text-gray-700',
          listItem: ({ isSelected }) =>
            `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded hover:bg-primary hover:text-white ${
              isSelected
                ? `text-white bg-primary`
                : `text-gray-500 hover:bg-cprimary-200 hover:text-cprimary-600`
            }`,
        }}
      />
    </div>
  )
}

export default AppSelect
