import React, { useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Img1 from '../../assets/home/image1.jpg'
import Img2 from '../../assets/home/image2.jpg'
import Img3 from '../../assets/home/image3.jpg'
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
} from 'react-icons/md'
import './index.css'

const slideImages = [
  {
    url: Img1,
    caption: {
      title: 'Welcome to Global Wood Market SA',
      paraph: 'The best timber exporters for the middle east and europe',
    },
  },
  {
    url: Img2,
    caption: {
      title: 'WIDE VARIETIES OF WOODEN LOGS AND TIMBER',
      paraph:
        'Choose from as many as 20 varieties of wood logs, lumber and timber',
    },
  },
  {
    url: Img3,
    caption: {
      title: 'TOP EXPORTER OF QUALITY TIMBER, LUMBER & LOGS',
      paraph: 'Choose the one suitable for you',
    },
  },
]

function Carousel() {
  let sliderRef = useRef(null)
  const next = () => {
    sliderRef.slickNext()
  }
  const previous = () => {
    sliderRef.slickPrev()
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    autoplaySpeed: 3000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }
  return (
    <div className='slider-container'>
      <div
        style={{
          position: 'relative',
          zIndex: '20',
          top: '50vh',
          fontSize: '30px',
          color: 'white',
        }}
        className='hidden md:block'
      >
        <MdKeyboardArrowLeft className='button leftBtn' onClick={previous} />
        <MdKeyboardArrowRight className='button rightBtn' onClick={next} />
      </div>
      <Slider
        ref={(slider) => {
          sliderRef = slider
        }}
        {...settings}
      >
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              style={{
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), #0008), url(${slideImage.url}) no-repeat center center / cover`,
                width: '100%',
                height: '100vh',
              }}
            >
              <div className='flex flex-col items-center justify-center pt-36 h-[93vh] mx-10'>
                <p className='uppercase  md:text-[45px] font-bold text-white pb-[10px] text-center'>
                  {slideImage.caption.title}
                </p>
                <p className='uppercase text-[18px] text-gray-100 text-center'>
                  {slideImage.caption.paraph}
                </p>
                <div className='uppercase text-[10px] py-[8px] px-[25px] md:text-[14px] border mt-10 md:mb-20 rounded-md border-[#6e6d6c] md:py-[14px] md:px-[40px] text-white font-[500px] hover:bg-[#4e1904]'>
                  <a href='#start'>start now</a>
                </div>
                <MdKeyboardArrowDown className='text-white text-3xl font-bold arrowDown' />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
