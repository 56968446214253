import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { products } from "../fakers";

const usePaginate = () => {
  // init search param
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    search: "",
  });

  const [page, setPage] = useState(parseInt(searchParams.get("page") ?? 1) - 1);
  const [search, setSearch] = useState(searchParams.get("search") ?? "");
  const [perPage, setPerPage] = useState(10);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [totalPage, setTotalPage] = useState(null);

  useEffect(() => {
    if (products !== 0) {
      setTotalPage(products?.length);
      // setTotalPage(headers["x-total-count"]);

      //setLastPage((parseInt(headers["x-total-count"]) / perPage).toFixed(0))
      // const lastPageTemp = parseInt(
      //   (parseInt(headers["x-total-count"]) / perPage).toString()
      // );
      // setLastPage(lastPageTemp);
      // setIsLastPage(lastPageTemp === page);
    }
  }, [products?.length]);

  const changePage = (value) => {
    if ((searchParams.get("page") ?? 1) !== 1) {
      setSearchParams((prev) => {
        prev.set("page", (parseInt(value) + 1).toString());
        return prev;
      });
    } else {
      loadPage();
    }
  };

  const loadPage = () => {
    setPage(parseInt(searchParams.get("page") ?? 1) - 1);
  };

  useEffect(() => {
    loadPage();
  }, [searchParams.get("page")]);

  const changePerPage = (value) => {
    setPerPage(value);
    if (page !== 0) {
      changePage(0);
    } else {
      loadPage();
    }
  };

  return {
    page,
    perPage,
    changePage,
    loadPage,
    changePerPage,
    isLastPage,
    totalPage,
    lastPage,
  };
};

export default usePaginate;
