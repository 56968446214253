import React, { useState } from 'react'
import { ImLocation } from 'react-icons/im'
import { FaPhone } from 'react-icons/fa6'
import { FaWhatsapp } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaGlobeAfrica } from 'react-icons/fa'
import { FaFacebookF } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { AiOutlineLinkedin } from 'react-icons/ai'
import pay1 from '../../assets/home/payment01.png'
import pay2 from '../../assets/home/payment02.webp'
import pay3 from '../../assets/home/payment03.png'
import Logo from '../../assets/home/logo1.jpg'
import AppSelect from '../Form/Select'
import { BeatLoader } from 'react-spinners'

const Contact = ({
  allCountries,
  formik,
  loading,
  country,
  handleContryChange,
}) => {
  return (
    <>
      <div className='bg-contact-bg py-7 px-10 lg:px-24 md:py-20 md:mt-20  bg-center bg-fixed bg-cover w-full'>
        <div className='md:container text-white w-full '>
          <div>
            <h2 className='text-center text-[18px] md:text-3xl font-medium capitalize mb-5'>
              contact us
            </h2>
            <p className='text-center text-sm font-normal md:px-28 mb-16 text-gray-200'>
              Reach us now with your queries, requirements, service question or
              quote requests, and we will be more than happy to help you in
              every possible way. Send us a message using the form below.
            </p>
          </div>
          <div className='flex flex-col justify-center items-center md:flex-row md:justify-between w-full'>
            <form
              onSubmit={formik.handleSubmit}
              className='grid grid-cols-1 gap-y-9  md:grid-cols-2 md:gap-x-6 md:pr-20 w-full'
            >
              <div className='w-full'>
                <input
                  className='focus:outline-none border border-gray-500 bg-transparent w-full h-12 pl-2 placeholder:font-light'
                  type='text'
                  name='name'
                  id='name'
                  placeholder='Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <span className='text-red-500'>
                  {formik.touched.name &&
                    formik.errors.name &&
                    formik.errors.name}
                </span>
              </div>
              <div className='w-full'>
                <input
                  className='focus:outline-none border border-gray-500 bg-transparent w-full h-12 pl-2 placeholder:font-light'
                  type='email'
                  name='email'
                  id='email'
                  placeholder='Email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <span className='text-red-500'>
                  {formik.touched.email &&
                    formik.errors.email &&
                    formik.errors.email}
                </span>
              </div>
              <div className='w-full'>
                <input
                  className='focus:outline-none border border-gray-500 bg-transparent w-full h-12 pl-2 placeholder:font-light'
                  type='number'
                  name='telephone'
                  id='telephone'
                  placeholder='Whatsapp No./Phone No.'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telephone}
                />
                <span className='text-red-500'>
                  {formik.touched.telephone &&
                    formik.errors.telephone &&
                    formik.errors.telephone}
                </span>
              </div>
              <AppSelect
                value={country}
                handleChange={handleContryChange}
                options={allCountries}
                placeholder={'Select your country'}
                textColor={'text-gray-400'}
                borderColor={'border-gray-500'}
              />
              <div className='w-full md:col-span-2'>
                <textarea
                  className='focus:outline-none  border border-gray-500 bg-transparent w-full h-64 pl-2 placeholder:font-light'
                  name='message'
                  id='message'
                  placeholder='Enter Your Message'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                ></textarea>
                <span className='text-red-500'>
                  {formik.touched.message &&
                    formik.errors.message &&
                    formik.errors.message}
                </span>
              </div>
              <button
                type='submit'
                className='md:col-span-2 w-full h-12 cursor-pointer hover:bg-primary/90 transition-all bg-[#4e1904] text-gray-300 font-normal capitalize'
              >
                {loading ? (
                  <BeatLoader loading={loading} color='#fff' />
                ) : (
                  'Send Now'
                )}
              </button>
            </form>
            <div className='md:w-[40%] lg:w-[75%]'>
              <h2 className='text-3xl font-medium my-5'>
                Ready to talk to sales?
              </h2>
              <p className='text-sm text-gray-200 mb-5'>
                Submit this form and a business expert will be in touch with
                lightning speed.
              </p>
              <h3 className='font-medium text-lg mb-3'>
                Operation and Production
              </h3>
              <p className='text-sm text-gray-200 mb-5'>
                Connect with us at the following:
              </p>
              <ul className='flex flex-col text-sm text-gray-200 gap-y-3'>
                <li className='flex items-center'>
                  <ImLocation className='mr-1 text-[15px]' /> Congo Brazzaville
                  Douala Cameroon (Douala)
                </li>
                <li className='flex items-center'>
                  <FaPhone className='mr-1 text-[15px]' />
                  Phone: +237 695 26 85 08
                </li>
                <li className='flex items-center'>
                  <FaWhatsapp className='mr-1 text-[20px]' />
                  Whatsapp:+237 695 26 85 08
                </li>
                <li className='flex items-center'>
                  <FaEnvelope className='mr-1 text-[15px]' />
                  Email:support@globalwoodmarketsa.com
                </li>
                <li className='flex items-center'>
                  <FaEnvelope className='mr-1 text-[15px]' />
                  Email:info@globalwoodmarketsa.com
                </li>
                <li className='flex items-center'>
                  <FaGlobeAfrica className='mr-1 text-[20px]' />
                  Website:www.globalwoodmarketsa.com
                </li>
              </ul>
              <div className='pt-5'>
                <h3 className='text-lg font-semibold pb-2'>
                  Accepted Payment Methods:
                </h3>
                <ul className='flex gap-x-3'>
                  <li>
                    <img src={pay1} alt='' />
                  </li>
                  <li>
                    <img src={pay2} alt='' />
                  </li>
                  <li>
                    <img src={pay3} alt='' />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.719863783367!2d9.688302036843325!3d4.026536424437563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106112c20b913d49%3A0x3ca80a1d889552e7!2sAve%20du%20General%20de%20Gaulle%2C%20Douala!5e0!3m2!1sen!2scm!4v1704961540983!5m2!1sen!2scm'
        allowFullScreen
        title='Ma localisation'
        className='w-full h-80'
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe> */}
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.1167856883435!2d9.8028806!3d3.9964034999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106173214d50b395%3A0x4f95a301237057fb!2sParc%20%C3%A0%20bois%20Yassa!5e0!3m2!1sen!2scm!4v1710250996576!5m2!1sen!2scm'
        allowFullScreen
        title='Ma localisation'
        className='w-full h-80'
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
      <div className='bg-[#411503] py-8 flex flex-col justify-center items-center place-content-center'>
        <img src={Logo} alt='Logo' className='w-14 h-14 rounded-full' />
        <div className='flex text-white mt-5 justify-between text-4xl gap-x-3 pl-5'>
          <a href='https://www.facebook.com/profile.php?id=100087215439446&mibextid=YMEMSu'>
            <FaFacebookF className='bg-[#340f00] p-2' />
          </a>
          <a href='https://x.com/GWM_SA_1?t=p3i9RubGzXJg0pd3uFZrXQ&s=09'>
            <FaTwitter className='bg-[#340f00] p-2' />
          </a>
          <a href='https://www.instagram.com/global_wood_?igsh=MXk1cG1ham5xeHNx'>
            <FaInstagram className='bg-[#340f00] p-2' />
          </a>
          <a href=' https://www.linkedin.com/in/cameroon-wood-market?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'>
            <AiOutlineLinkedin className='bg-[#340f00] p-2' />
          </a>
        </div>
      </div>
      <div className='bg-[#340f00] text-white px-[8%] py-5 text-sm font-normal'>
        <p className='mb-3'>
          2012 Global Wood Market SA has provided this website for informational
          purposes only. Please contact our Global wood market SA for more
          information. We happily welcome our clients in Cameroon and Republic
          of Congo, Africa, Iraq, Vietnam, Malaysia, Europe, Asia, Saudi Arabia,
          Kuwait, United Arab Emirates (UAE), Qatar, Bahrain and Oman in all GCC
          countries.
        </p>
        <p className='mb-3'>
          We serve entire cities in Cameroon and the Republic of Congo
        </p>
        <p className='mb-3'>
          Global Wood Market SA - Supplier of exotics from Africa - Quality wood
          wholesaler | © 2012 Global Wood Market SA
        </p>
      </div>
    </>
  )
}

export default Contact
