import React, { useState } from 'react'
import Log from '../../assets/home/logo1.jpg'
import { NavLink } from 'react-router-dom'
import './index.css'
import useTranslate from '../../hooks/useTranslate'
import fr from '../../assets/fr.svg'
import en from '../../assets/us.svg'
import { useTranslation } from 'react-i18next'
import { MdClose, MdMenu } from 'react-icons/md'

const NavbarLink = ({ position }) => {
  const { currentLanguageCode, handleLanguageChange } = useTranslate()
  const [t] = useTranslation()
  const [toggleNavbar, setToggleNavbar] = useState(false)
  const handleToggleNavbar = () => setToggleNavbar(!toggleNavbar)

  return (
    <div
      className={`bg-[#4e1904] w-full py-4 z-20 px-10 md:px-[10%]   ${position}`}
    >
      <div className={`flex gap-3 justify-between  items-center  `}>
        <img src={Log} alt='Logo' className='w-12 h-12 rounded-full' />

        <div className='hidden sm:block'>
          <div className='flex gap-x-[50px] mx-12 md:mx-0 mb-6 md:mb-0  text-white font-semibold'>
            <NavLink className='nav' to='/'>
              {t('navLink.home')}
            </NavLink>
            <NavLink className='nav' to='/about'>
              {t('navLink.aboutUs')}
            </NavLink>
            <NavLink className='nav' to='/contact'>
              {t('navLink.contactUs')}
            </NavLink>
          </div>
        </div>

        <div className='changeLanguage hidden sm:block'>
          <div className='dropdown'>
            <button className='dropbtn focus:outline-none text-white'>
              {currentLanguageCode === 'fr' ? (
                <img src={fr} width={16} alt='lang' className='mx-2' />
              ) : (
                <img src={en} className='mx-2' alt='lang' width={16} />
              )}
              {currentLanguageCode === 'fr' ? 'FRENCH' : 'ENGLISH'}
            </button>

            <div className='dropdown-content'>
              <div
                onClick={() => handleLanguageChange('fr')}
                className='focus:outline-none cursor-pointer'
              >
                <img src={fr} width={16} alt='lang' /> FRENCH
              </div>
              <div
                onClick={() => handleLanguageChange('en')}
                className='focus:outline-none cursor-pointer'
              >
                <img src={en} width={16} alt='lang' /> English{' '}
              </div>
            </div>
          </div>
        </div>

        <div
          className='block sm:hidden cursor-pointer transition-all duration-200'
          onClick={handleToggleNavbar}
        >
          {!toggleNavbar ? (
            <MdMenu className='text-white text-xl' />
          ) : (
            <MdClose className='text-white text-xl' />
          )}
        </div>
      </div>

      {/* mobile nav */}
      <div
        className={`${
          toggleNavbar ? 'block' : 'hidden'
        } transition-all duration-300 sm:hidden mt-5`}
      >
        <div className='flex flex-col justify-center items-center gap-2'>
          <div className='flex gap-[50px] mx-12 md:mx-0 mb-6 md:mb-0 text-white font-semibold text-center'>
            <NavLink className='nav' to='/'>
              {t('navLink.home')}
            </NavLink>
            <NavLink className='nav' to='/about'>
              {t('navLink.aboutUs')}
            </NavLink>
            <NavLink className='nav' to='/contact'>
              {t('navLink.contactUs')}
            </NavLink>
          </div>
          <div className='changeLanguage '>
            <div className='dropdown'>
              <button className='dropbtn focus:outline-none text-white'>
                {currentLanguageCode === 'fr' ? (
                  <img src={fr} width={16} alt='lang' className='mx-2' />
                ) : (
                  <img src={en} className='mx-2' alt='lang' width={16} />
                )}
                {currentLanguageCode === 'fr' ? 'FRENCH' : 'ENGLISH'}
              </button>

              <div className='dropdown-content'>
                <div
                  onClick={() => handleLanguageChange('fr')}
                  className='focus:outline-none cursor-pointer'
                >
                  <img src={fr} width={16} alt='lang' /> FRENCH
                </div>
                <div
                  onClick={() => handleLanguageChange('en')}
                  className='focus:outline-none cursor-pointer'
                >
                  <img src={en} width={16} alt='lang' /> English{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarLink
