import React from 'react'

const Pagination = ({
  totalPages,
  postPerPage,
  setCurrentPage,
  currentPage,
}) => {
  let pages = []

  for (let i = 1; i <= Math.ceil(totalPages / postPerPage); i++) {
    pages.push(i)
  }

  return (
    <div className='w-full flex justify-center items-center gap-2 mt-3'>
      {pages.map((page, index) => (
        <button
          key={index}
          onClick={() => setCurrentPage(page)}
          className={[
            'w-8 h-8 flex justify-center items-center rounded-full focus:outline-none',
            currentPage === page
              ? 'text-white bg-primary'
              : 'text-black hover:bg-primary/20 active:bg-primary/40',
          ].join(' ')}
        >
          {page}
        </button>
      ))}
    </div>
  )
}

export default Pagination
