import React, { useEffect, useState } from 'react'
import Banner from '../../components/Banner'
import NavbarLink from '../../components/NavbarLink/NavbarLink'
import Navbar from '../../components/Navbar'
import Article from '../../components/Article/Article'
import wood from '../../assets/about/Wood-Harvesting-100x100.jpg'
import Contact from '../../components/Contact'
import { Link, useLocation, useParams } from 'react-router-dom'
import { products } from '../../fakers'
import useCountries from '../../hooks/useCountries'
import useSendMail from '../../hooks/useSendMail'
import Popups from '../../components/Popups/Popups'

const Search = () => {
  const { search } = useLocation()
  const [searchedProducts, setSearchedProducts] = useState([])

  let searchParam = search.slice(1)

  useEffect(() => {
    const result = products?.filter((item) =>
      item.name.toLowerCase().includes(searchParam)
    )
    setSearchedProducts(result)
  }, [searchParam])

  const { allCountries } = useCountries()

  const { formik, loading, country, handleContryChange } = useSendMail()

  console.log(searchParam)

  return (
    <>
      <Banner />
      <Popups />
      <NavbarLink position='sticky top-0' />
      <Navbar
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />
      {/* content */}
      <div className='flex flex-col md:flex-row gap-2 w-full px-content flex-wrap'>
        {/* left side */}
        <div className='flex-1 py-5 space-y-8 mb-5'>
          <div>
            <p className='text-4xl font-bold text-primary'>
              Search Results for : {searchParam}
            </p>

            <div className='space-y-5 mt-5'>
              {searchedProducts?.length > 0 ? (
                searchedProducts.map((item, index) => (
                  <div
                    key={index}
                    className='border border-primary p-5 flex flex-col gap-3'
                  >
                    <Link
                      to={`/product/${item.url}`}
                      state={item}
                      className='font-bold text-xl text-primary'
                    >
                      {item.name}
                    </Link>
                    <div className='flex items-center gap-2 text-sm'>
                      <span className='font-semibold text-gray-500'>
                        Availability :
                      </span>
                      <span className=' text-gray-500'>
                        {item.availability}
                      </span>
                    </div>
                    <div className='flex items-center gap-2 text-sm'>
                      <span className='font-semibold text-gray-500'>
                        Size :
                      </span>
                      <span className=' text-gray-500'>
                        {item.size === null ? 'N/A' : item.size}
                      </span>
                    </div>
                    <p className='text-gray-500 text-sm line-clamp-3'>
                      {item.description.p1}
                    </p>
                  </div>
                ))
              ) : (
                <div className='flex justify-center items-center w-full h-64'>
                  <p className='text-lg font-semibold'>No result found !!</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* right side */}
        <div className='flex-1 md:flex-none '>
          <Article
            title1='Anigre Wood'
            src1={wood}
            alt1='wood'
            title2='Wood Chips'
            src2={wood}
            alt2='wood'
            title3='Birch Wood'
            src3={wood}
            alt3='wood'
            title4='Wenge Timber'
            src4={wood}
            alt4='wood'
            title5='Ekop Beli Slabs'
            src5={wood}
            alt5='wood'
          />
        </div>
      </div>
      {/* contact */}
      <Contact
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />{' '}
    </>
  )
}

export default Search
