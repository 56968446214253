import React from 'react'
import Contact from '../../components/Contact/index.jsx'
import Banner from '../../components/Banner/index.jsx'
import Navbar from '../../components/Navbar/index.jsx'
import NavbarLink from '../../components/NavbarLink/NavbarLink.jsx'
import useCountries from '../../hooks/useCountries.js'
import useSendMail from '../../hooks/useSendMail.js'

function Contactus() {
  const { allCountries } = useCountries()

  const { formik, loading, country, handleContryChange } = useSendMail()

  return (
    <>
      <Banner />
      <NavbarLink position='sticky top-0' />
      <Navbar
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />{' '}
      <Contact
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />{' '}
    </>
  )
}

export default Contactus
