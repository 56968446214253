import cookies from "js-cookie";
import { useContext } from "react";
import { LangContext } from "../context/LangContext";

export default function useTranslate() {
  const { handleLanguageChange } = useContext(LangContext);

  const currentLanguageCode = cookies.get("i18next") || "fr";

  return {
    currentLanguageCode,
    handleLanguageChange,
  };
}
