import React from 'react'
import Banner from '../../components/Banner/index.jsx'
import Navbar from '../../components/Navbar/index.jsx'
import Contact from '../../components/Contact/index.jsx'
import SingleBanner from '../../components/SingleBanner/SingleBanner.jsx'
import NavbarLink from '../../components/NavbarLink/NavbarLink.jsx'
import Article from '../../components/Article/Article.jsx'
import wood from '../../assets/about/Wood-Harvesting-100x100.jpg'
import certification from '../../assets/about/Certification-768x330.jpg'
import './index.css'
import SmallWoodOrder from '../../assets/about/Small-Wood-Order-768x576.jpg'
import slide1 from '../../assets/home/slider01.webp'
import slide2 from '../../assets/home/slider02.webp'
import woodTransp from '../../assets/about/Wood-Transportation-768x576.jpg'
import useCountries from '../../hooks/useCountries.js'
import useSendMail from '../../hooks/useSendMail.js'
import Popups from '../../components/Popups/Popups.jsx'

const GlobalService = ({ title, text, src }) => {
  return (
    <div>
      <h3 className='font-bold text-base text-[#4e1904] mb-5'>{title}</h3>
      <p className='mb-5'>{text}</p>
      <img src={src} alt='Small-Wood-Order' className='mb-5' />
    </div>
  )
}

function About() {
  const { allCountries } = useCountries()

  const { formik, loading, country, handleContryChange } = useSendMail()

  return (
    <>
      <Banner />
      <Popups />
      <NavbarLink position='sticky top-0' />
      <Navbar
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />{' '}
      <div className='about'>
        <SingleBanner
          title='About Us'
          para='WE ARE HERE BECAUE YOU ARE THERE'
        />
        <div className='px-7 content-about flex flex-col w-full md:flex-row justify-center lg:px-24 pb-12'>
          <div className='mainAbout text-base text-gray-600 '>
            <div className='mb-5'>
              <h2 className='text-xl font-bold text-gray-800 mb-5'>
                Welcome To Global Wood Market!
              </h2>
              <p className='mb-5 leading-6'>
                Global wood market SA is a Douala (Cameroon) based
                Proprietorship Firm that is engaged in retailing, wholesaling
                and export of premium trade quality West African Timber in a
                range of top wood species including Teak, Sapele (Mahogany),
                Okan, Padauk, Ebony, Movingui, among others.
              </p>
              <p className='mb-5 leading-6'>
                Founded more than 30 years ago as a small wood supplier in
                Cameroon, Douala, Global wood market SA is now the biggest
                exporter & supplier of high-quality African wood logs & timber,
                with export to over 20 countries in the world. As of now, our
                Cameroon sawmill factory has 100+ experienced workers and our
                average monthly capacity is 3000 m3.
              </p>
            </div>
            <div className='mb-5'>
              <h2 className='text-xl font-bold text-gray-800 mb-5'>Our Goal</h2>
              <p className='leading-6'>
                Become the number one and most trusted supplier & distributor of
                high-quality African wood logs and timber around the world.
              </p>
            </div>
            <div>
              <h2 className='text-xl font-bold text-gray-800 mb-5'>
                Our Core Values
              </h2>
              <p className='font-bold mb-5 leading-6'>
                At Global wood market SA, we are driven by the following three
                core values:
              </p>
              <div className='mb-5'>
                <h3 className='font-bold text-base mb-5'>1. Quality</h3>
                <p className='leading-6'>
                  We strive to keep delivering the highest quality African wood
                  to our clients worldwide. It is our responsibility to maintain
                  the promises we make to our customers. And having an in-house
                  quality control team enables us to keep the promise of
                  high-quality wood products.
                </p>
              </div>
              <div className='mb-5'>
                <h3 className='font-bold text-base mb-5'>2. Reliability</h3>
                <p className='leading-6'>
                  We understand how important it is for furniture businesses,
                  construction projects, etc. to get their raw material and wood
                  on time. So, we make it our mission to deliver top quality
                  wood always on time to our clients worldwide. This is one of
                  the reasons why we are trusted by hundreds of furniture makers
                  globally.
                </p>
              </div>
              <div className='mb-5'>
                <h3 className='font-bold text-base mb-5'>3. Care</h3>
                <p className='leading-6'>
                  Most importantly, we care for our customers and never think
                  twice before going that extra mile to ensure a satisfactory
                  customer experience. We have a dedicated support team that is
                  here to listen to and solve customer issues on a priority
                  basis.
                </p>
              </div>
            </div>
            <div className='mb-5'>
              <h2 className='text-xl font-bold text-gray-800 mb-5'>
                Information on Certification
              </h2>
              <img src={certification} alt='certification' />
              <ul className='px-6 mt-5'>
                <li className='point mb-3 leading-6'>
                  Global wood market SA Certified By{' '}
                  <strong>
                    ISPM ( International Standards for Phytosanitary Measures ){' '}
                    sets high standard regulation of wood packaging material in
                    international trade.{' '}
                  </strong>
                  sets high standard regulation of wood packaging material in
                  international trade.
                </li>
                <li className='point mb-3 leading-6'>
                  Global wood market SA Certified By{' '}
                  <strong>
                    CITES ( Convention on International Trade in Endangered
                    Species ){' '}
                  </strong>
                  ensures the international trade of wood does not threaten the
                  survival of the endangered species.
                </li>
                <li className='point mb-3 leading-6'>
                  Global wood market SA Certified By{' '}
                  <strong>ICF ( Inspeciton Certificate of Fumigation ) </strong>
                  confirms that all the wood exports have been fumigated on
                  regular basis.
                </li>
              </ul>
            </div>
            <div className='mb-5 mt-10'>
              <h2 className='text-xl font-bold text-gray-800 mb-5'>
                Global wood market SA Services
              </h2>
              <GlobalService
                title='Sustainable forest management'
                text='We provide customers with the goods and services they expect from the forest without compromising the ability of future generations to do the same.'
                src={SmallWoodOrder}
              />
              <GlobalService
                title='wide variety of hardwood species'
                text='Our products meet the highest quality standards; very competitively priced and are ideal for a variety of applications..'
                src={slide1}
              />
              <GlobalService
                title='Wood processing and delivery'
                text='We have the capacity to process large volumes of orders and guarantee punctual and reliable deliveries regardless of the country or port of delivery.'
                src={slide2}
              />{' '}
              <br />
              <GlobalService
                title='After sales service'
                text='We respect customer feedback after receiving the goods.
We appreciate every comment and suggestion.
We provide all export documents'
                src={woodTransp}
              />
            </div>
            <div className='mb-5 mt-10'>
              <h2 className='text-xl font-bold text-gray-800 mb-5'>
                Benefits of Buying Wood from Global Wood Market SA
              </h2>
              <ul className='px-6 mt-5'>
                <li className='point leading-6'>
                  We have our own mill, where all the wood is cut & processed,
                  thus keeping the cost minimal and the quality superior.
                </li>
                <li className='point  leading-6'>
                  Worldwide delivery. We deliver timber to more than 20
                  countries in Asia, Europe, Canada, Africa, and others.
                </li>
                <li className='point  leading-6'>
                  Order in your preferred size and length. The best advantage of
                  having our own sawmill is that we are able to offer timber cut
                  in custom sizes and length as required by our customers.
                </li>
                <li className='point leading-6'>
                  Safe & Timely Delivery. Our fully-monitored & systematic
                  process ensures you get the best quality timber in the best
                  condition and on time.
                </li>
                <li className='point leading-6'>
                  WE ARE A CERTIFIED AND AWARDED AFRICAN TIMBER DISTRIBUTOR
                  COMPANY.
                </li>
              </ul>
              <p className='my-3 leading-6'>
                We are among the top timber exporter companies in the Cameroon{' '}
                area and deliver freshly sawn timber as well as timber round
                logs to businesses and individuals in both the domestic and
                international markets. Our international timber export services
                are available in the Middle East, Europe, Malaysia, Iraq and all
                the six GCC (Arab) countries.
              </p>
              <p className='my-3 leading-6'>
                We “Global Wood Market SA” have also partnered with the Ministry
                Of Forestry and Wildlife in Douala City, to promote mutual
                research and cooperation with the intent to enrich our
                manufacturing capabilities and resources with the latest tools
                and technologies to ensure the production of optimum quality
                wood timber.
              </p>
              <p className='my-3 leading-6'>
                Being one of the oldest timber exporting firms in the region, we
                deliver a wide range of timber species owing to our years of
                experience and a great team of production personnel. At Global
                Wood Market SA, we believe in catering our best to each buyer’s
                request.
              </p>
              <p className='my-3 leading-6'>
                Our product categories ( <a href={''}>wood species</a>) include
                but are not limited to – Kosso, Afromosia, Beli, Bubinga, Ebony,
                Iroko, Mahogany, Maobi, Padauk, Azobe, Bilinga, Doussie, Ekop,
                Ironwood, Makore, Okan, Rosewood, etc. You can find more
                information on our ‘Wood Species’ page.
              </p>
            </div>
          </div>
          <div className='article ml-12'>
            <Article
              title1='Anigre Wood'
              src1={wood}
              alt1='wood'
              title2='Wood Chips'
              src2={wood}
              alt2='wood'
              title3='Birch Wood'
              src3={wood}
              alt3='wood'
              title4='Wenge Timber'
              src4={wood}
              alt4='wood'
              title5='Ekop Beli Slabs'
              src5={wood}
              alt5='wood'
            />
          </div>
        </div>
      </div>
      <Contact
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />{' '}
    </>
  )
}

export default About
