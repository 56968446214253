import React from 'react'

const SingleBanner = ({ title, para }) => {
  return (
    <>
      <div className='bg-aboutUs-bg py-5 md:py-0 md:h-60 bg-cover object-cover bg-no-repeat text-white flex flex-col justify-center pl-7 lg:pl-24 mb-9'>
        <div className='content'>
          <h1 className='text-[20px] md:text-4xl font-semibold mb-1 capitalize'>
            {title}
          </h1>
          <p className='text-sm uppercase'>{para}</p>
        </div>
      </div>
    </>
  )
}

export default SingleBanner
