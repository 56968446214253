import React from 'react'
import ProductDetailForm from '../ProductDetailForm/ProductDetailForm'
import useCountries from '../../hooks/useCountries'
import useSendMail from '../../hooks/useSendMail'

const ProductCategoryDes = ({ id }) => {
  const { allCountries } = useCountries()

  const { formik, loading, country, handleContryChange } = useSendMail()
  return (
    <>
      {id === 'African Round Wood Log Species' && (
        <div className='space-y-8 text-sm text-gray-800'>
          <div className='space-y-5'>
            <p className='text-xl font-bold'>
              African Round Wood Log Exporter, Supplier, and wholesaler
            </p>
            <p className=''>
              Global Wood Market SA is the{' '}
              <span className='font-bold'>
                largest supplier of top-quality African wood round logs
              </span>{' '}
              in the region. We are involved in the retailing, wholesaling and
              export of all the popular varieties African round logs. At
              present, we provide timber export services to a number of
              countries including all the six GCC (Gulf) countries, Malaysia,
              Iraq, Vietnam, the Middle East and some other Asian and European
              countries.
            </p>
          </div>
          <div className='space-y-5'>
            <p className='text-xl font-bold'>
              Type of African Wood Round Logs (African Timber Exporter)
            </p>
            <p className=''>
              African wood is known for their diversity in color, strength, and
              uses. You can find both softwood and hardwood trees in Africa. So,
              whatever your purpose is, you can find the right wood species with
              us. At Global Wood Market SA, we specialize in the supply of large
              varieties of African wood species such as{' '}
              <span className='font-bold'>
                Teak, Mahogany, Afromosia, Ebony, Blackwood, Movingui, Zebrawood
                logs, Beli logs,
              </span>{' '}
              and many more.
            </p>
          </div>
        </div>
      )}

      {id === 'African Sawn Timber' && (
        <div className='space-y-8 text-sm text-gray-800'>
          <div className='space-y-5'>
            <p className='text-xl font-bold'>
              African Sawn Timber Exporter, Supplier, and wholesaler
            </p>
            <p className=''>
              Global Wood Market SA is a renowned{' '}
              <span className='font-bold'>supplier of African sawn timber</span>{' '}
              that is produced in the company owned forest plantation. Our local
              facilities based in Douala, Cameroon house several acres of
              rainforest along with resources to export the wood in the
              export-ready form. Whether you need finished or unfinished African
              Timber, we are the best option in Cameroon. Presently, we are
              exporting our sawn timber to a number of countries like Malaysia,
              Europe and the middle East.
            </p>
          </div>
          <div className='space-y-5'>
            <p className='text-xl font-bold'>
              Type of African Sawn Timber Supplying By (Global Wood Market SA)
            </p>
            <p className=''>
              Sawn timber is a processed wood that is supplied in both finished
              and unfinished form by us. Whether you want timber for home
              construction or any other purpose, we can help you get the right
              wood. Our sawn timbers are available in many sizes and in a
              diversed variety of wood species suitable for different kind of
              wood projects. Some examples include
              <span className='font-bold'>
                Afromosla Sawn timber, Azobe, Bilinga Sawn timber, Bubinga,
                Doussie sawn timber, Ebony (Black Ebony), Ekop-Beli and Iroko
                sawn Timber,
              </span>{' '}
              and many more.
            </p>
          </div>
        </div>
      )}

      {id === 'African Wood Slabs' && (
        <div className='space-y-3 text-sm text-gray-800'>
          <p className=''>
            Looking to buy the best quality African Hardwood slabs at cheap
            prices? Contact <a href='/'>Global Wood Market SA</a> for all your
            hardwood needs. We are the only genuine and reputated seller &
            exporter of top quality African hardwood slabs in all leading
            species including Bubinga, Sapele, Padouk, Mahogany, Iroko,
            Ekop-Bell, Tali, and Ekop Naga.
          </p>
          <p className=''>
            At Global Wood Market SA, you can order live edge wood slabs in a
            large variety of wood species and in any custom length, width or
            thickness you want. Make sure to select your preferred wood slab
            species, length, width, and thickness while placing your order.
          </p>
          <p className=''>
            Our wood slabs are ideal for making desktops, tabletops, coffee
            tables, wall arts, conference tables, kitchen islands, bar tops,
            nightstands, benches, end tables, stools, dining tables, and
            gathering tables. Basically, they'll look beautiful across any and
            all fine furniture making projects you want to use them for.
          </p>
          <p className=''>
            As one of the largest producers & exporters of African wood in the
            world, we dominate the market in terms of selling the best quality
            live-edge wood slabs at the cheapest prices. We cut all the hardwood
            at our own mill and prepare slabs in the predefined structure, sizes
            and shapes while maintaining its quality. Also, all our slabs are
            kiln-dried to ensure maximum robustness stability. We sell and
            export hardwood slabs directly to our customers all over the world
            at the best rates.
          </p>
          <p className=''>
            We at Global Wood Market SA follow strictly sustainable wood
            harvesting practices. All the{' '}
            <span className='font-bold'>wood slabs for sale</span> provided by
            us come from our local forests which are harvested in line with
            regional sustainability guidelines and with a focus on replantation
          </p>
          <p className=''>
            If you have any questions or want to want African hardwood slabs at
            affordable prices, call us at{' '}
            <span className='font-bold'>
              +237 695 268 508 or drop an email to info@globalwoodmarketsa.com
            </span>
          </p>
        </div>
      )}

      {id === 'Wood Boards' && (
        <div className='space-y-5 text-sm text-gray-800'>
          <p>
            Wood board is a flat, usually thin, rectangular sheet made of either
            wood or composite material like MDF. The price of wood board depends
            on its type and the material used.
          </p>
          <p>
            If you're looking to buy the best quality wood boards at a
            reasonable price from a reliable supplier, then you are at the right
            place. Global Wood Market SA is a top supplier & exporter of quality
            wooden boards, timber and wood logs. We ship wood boards to all
            major countries and cities around the world including Australia,
            Canada, America, UAE, Iraq, Malaysia, and many other countries in
            Europe and Asia
          </p>
          <div className='space-y-3'>
            <p className='font-bold'>Types of Wood Boards</p>
            <p>
              It can be of many types including natural and engineered wood
              boards. Natural wood boards are made of actual wood timber and are
              generally of the best quality and the most expensive. Engineered
              wood boards are made of engineered wood, which is a composite wood
              made by combining wood particles, fibres, veneers and lumbar
              boards together with adhesive such as glue.
            </p>
            <p>
              Engineered wood boards can be further categorised into fibreboard,
              particleboard or chipboard, laminated board, oriented strand
              board, composite board and others based on the type of materials
              used in forming them. MDF or medium-density fiberboard is also a
              type of engineered wood board
            </p>
            <p>
              Based on processing, the boards can be categorised as rough,
              planed and S4S (sanded lumbar). Based on species, wood boards are
              generally of two types - softwood boards and hardwood boards.
              Softwood boards are made of soft wood such as pine, cedar, redwood
              and fir. Hardwoods are made hard wood like teak, white oak, black
              walnut, etc
            </p>
          </div>
          <div className='space-y-3'>
            <p className='font-bold capitalize'>
              How to buy the best wood boards online
            </p>
            <p>
              We at Global Wood Market SA supply all top varieties of lumbar
              boards in a wide range of species such as hardwood and softwood.
              Our wood boards are madeup of premium quality wood and other
              materials and processed locally in our mill located in Douala,
              Cameroon. We take full guarantee of the quality and price of our
              wood boards products. Also we supply to all major locations around
              the globe and can home deliver wood boards to your doorstep in a
              large number of countries and major cities
            </p>
            <p>
              So, what are you waiting for ? Place your wood board order today
              and select from our wide range of wood species. You can also call
              us or send an email to know more about about our wood board
              varieties, types, quality and price
            </p>
          </div>
        </div>
      )}

      {id === 'Plywood' && (
        <div className='space-y-5 text-sm text-gray-800'>
          <p>
            Plywood is a man-made wood that is manufactured by combining two
            layers of veneer called plies together with each other by using
            glue. The result is a solid wood product that is sometimes even more
            stable, stronger and more durable than actual wood.{' '}
          </p>
          <p>
            If you're looking to buy the best quality plywood at the lowest
            price online with doorstep delivery anywhere in the world, you're at
            the right place. Global Wood Market SA is a leading manufacturer,
            dealer & exporter of top quality wood products including plywood and
            engineered wood to businesses and consumers worldwide. We locally
            manufacture and process all wood and take full guarantee of the
            quality and price of our products.
          </p>
          <p className='font-bold'>Benefits Of Plywood</p>
          <p>
            Plywood is manufactured by combining layers of wood veneer such that
            adjacent layers have their grains rotated up to 90 degrees so that
            wood resin and fiber sheets bind to form a strong composite
            material. This type of engineered wood is very durable and has many
            other benefits including :
            <ul className='mt-4'>
              <li>Doesn't split when nailed at the edge,</li>
              <li>Has better dimensional stability,</li>
              <li>Doesn't expand or shrink alot,</li>
              <li>Doesn't expand or shrink alot,</li>
              <li>
                Has consistent strength across all directions of the panel.
              </li>
            </ul>
            It has high stiffness and good resistance against warping. Plywood
            can be of different types and may range from poor to great in
            quality depending on the number and arrangement of plies.
          </p>
          <p>
            In general, it has the following 8 types:
            <ol className='mt-4'>
              <li>
                <b>Softwood</b> - This type of plywood is made of softwoods like
                cedar, pine, fir or spruce and is generally used for
                construction purposes.
              </li>
              <li>
                <b>Hardwood</b> - This type of plywood is made of hardwoods like
                oak, beech and mahogany and is known for having excellent
                strength and stiffness.
              </li>
              <li>
                <b>Tropical</b> is madeup of tropical hardwood species.
              </li>
              <li>
                <b>Aircraft</b> is madeup of mahogany, spruce or birch and has
                extraordinary strength and resistance characteristics.
              </li>
              <li>Decorative</li>
              <li>Flexible</li>
              <li>Marine</li>
              <li>
                Others include fire retardant, moisture-resistant, wire mesh,
                pressure-treated plywood, etc
              </li>
            </ol>
          </p>
          <p className='font-bold'>Buy Plywood Online at The Best Prices</p>
          <p>
            Buy premium quality, high-strength plywood from Global Wood Market
            SA at the guaranteed best price online. We deliver wood to all major
            locations around the world. All plywoods are locally made and
            processed at our Cameroon mill and sent out to all customers with
            fast delivery
          </p>
          <p>
            So, what are you waiting for ? Place your order today and select
            from our wide range of wood species. Call us or send an email to
            enquire about about our plywood varieties, types, quality and price
          </p>
          <ProductDetailForm
            allCountries={allCountries}
            formik={formik}
            loading={loading}
            country={country}
            handleContryChange={handleContryChange}
          />
        </div>
      )}
    </>
  )
}

export default ProductCategoryDes
