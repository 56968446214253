import React, { useState } from 'react'
import Banner from '../../components/Banner'
import NavbarLink from '../../components/NavbarLink/NavbarLink'
import Navbar from '../../components/Navbar'
import Article from '../../components/Article/Article'
import wood from '../../assets/about/Wood-Harvesting-100x100.jpg'
import Contact from '../../components/Contact'
import SingleBanner from '../../components/SingleBanner/SingleBanner'
import { products } from '../../fakers'
import ProductDetailCard from '../../components/ProductDetailCard/ProductDetailCard'
import Paginate from '../../components/pagination/paginate'
import usePaginate from '../../hooks/usePaginate'
import { useParams } from 'react-router-dom'
import ProductCategoryDes from '../../components/ProductCategoryDes/ProductCategoryDes'
import Pagination from '../../components/pagination/Pagination'
import useCountries from '../../hooks/useCountries'
import useSendMail from '../../hooks/useSendMail'
import Popups from '../../components/Popups/Popups'

const ProductCategory = () => {
  const { id } = useParams()
  const {
    page,
    perPage,
    changePage,
    loadPage,
    changePerPage,
    isLastPage,
    totalPage,
    lastPage,
  } = usePaginate()

  const [currentPage, setCurrentPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(8)

  const lastPostIndex = currentPage * postPerPage
  const firstPostIndex = lastPostIndex - postPerPage
  const filteredProducts = products?.filter((item) => item.category === id)
  const paginatedProducts = filteredProducts?.slice(
    firstPostIndex,
    lastPostIndex
  )

  const { allCountries } = useCountries()

  const { formik, loading, country, handleContryChange } = useSendMail()

  return (
    <>
      <Banner />
      <Popups />
      <NavbarLink position='sticky top-0' />
      <Navbar
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />
      <SingleBanner title={id} />
      {/* content */}
      <div className='flex flex-col md:flex-row gap-2 w-full px-content flex-wrap'>
        {/* left side */}
        <div className='flex-1 py-5 space-y-8 mb-5'>
          <ProductCategoryDes id={id} />

          {id !== 'Plywood' && (
            <div className='space-y-4'>
              <div className='flex justify-between items-center pb-3 border-b border-b-gray-500'>
                <p className='uppercase text-gray-600 text-lg'>
                  Showing {firstPostIndex + 1} -{' '}
                  {lastPostIndex >= filteredProducts.length
                    ? filteredProducts.length
                    : lastPostIndex}{' '}
                  of {filteredProducts?.length} results
                </p>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'>
                {paginatedProducts?.map((item, index) => (
                  <ProductDetailCard
                    key={index}
                    name={item.name}
                    item={item}
                    img={item.images[2]}
                  />
                ))}
              </div>

              {/* pagination */}
              {/* <Paginate
                page={page}
                perPage={perPage}
                changePerPage={changePerPage}
                changePage={changePage}
                isLastPage={isLastPage}
                lastPage={lastPage}
              /> */}

              <Pagination
                totalPages={filteredProducts.length}
                postPerPage={postPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>

        {/* right side */}
        <div className='flex-1 md:flex-none '>
          <Article
            title1='Anigre Wood'
            src1={wood}
            alt1='wood'
            title2='Wood Chips'
            src2={wood}
            alt2='wood'
            title3='Birch Wood'
            src3={wood}
            alt3='wood'
            title4='Wenge Timber'
            src4={wood}
            alt4='wood'
            title5='Ekop Beli Slabs'
            src5={wood}
            alt5='wood'
          />
        </div>
      </div>
      {/* contact */}
      <Contact
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />{' '}
    </>
  )
}

export default ProductCategory
