import React from 'react'
import whatsapp from '../../assets/home/whatsapp-fix-icon.webp'
import phone from '../../assets/home/phone-icon.webp'
import mail from '../../assets/home/mail-icon.webp'
import { Link } from 'react-router-dom'
import './index.css'

const Popups = () => {
  return (
    <div className='z-50 absolute'>
      <Link to='https://wa.me/message/73UKHC6YY55LB1'>
        <img
          src={whatsapp}
          alt='whatsapp'
          className='fixed bottom-8 left-5 w-12'
        />
      </Link>
      <div className='side-contact'>
        <p className='phone'>+237695268508</p>
        <p className='mail'>support@globalwoodmarketsa.com</p>
      </div>
    </div>
  )
}

export default Popups
