import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './index.css'
import TopRated from '../TopRated/TopRated'
import pay1 from '../../assets/home/payment01.png'
import pay2 from '../../assets/home/payment02.webp'
import pay3 from '../../assets/home/payment03.png'
import { products, topProduct } from '../../fakers'

const Figure = ({ title }) => {
  return (
    <div className='figure text-white'>
      <div className='bg-[#4e1904] py-2 pl-4 pr-2 font-medium text-lg'>
        {title}
      </div>
      <div className='triangle-down ml-4'></div>
    </div>
  )
}

const ProductLink = ({ link }) => {
  return (
    <Link
      to={`/product-category/${link}`}
      className='border-b border-black pb-1 mb-3'
    >
      {link}
    </Link>
  )
}

const Article = ({
  title1,
  src1,
  alt1,
  title2,
  src2,
  alt2,
  title3,
  src3,
  alt3,
  title4,
  src4,
  alt4,
  title5,
  src5,
  alt5,
}) => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  return (
    <div className='bg-[#f7f7f7] w-full md:w-64 lg:w-72'>
      <Figure title='Search Products' />
      <div className=' flex md:flex-col lg:flex-row py-7 px-2 '>
        <input
          type='search'
          name='text'
          id='text'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='block placeholder:text-sm  w-full rounded-xs border-0 py-1.5 pl-4  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          placeholder='Search products...'
        />
        <button
          className='hover:bg-[#683e0b] bg-[#4e1904] flex items-center lg:ml-2 md:py-2 md:mt-2 lg:mt-0 md:pl-4 text-white text-sm lg:pr-8'
          onClick={() => navigate(`/search?${search}`)}
        >
          Search
        </button>
      </div>
      <Figure title='product categories' />
      <div className='text-sm font-medium flex flex-col justify-center py-6 px-2'>
        <ProductLink link={'African Round Wood Log Species'} />
        <ProductLink link='African Sawn Timber' />
        <ProductLink link='African Wood Slabs' />
        <ProductLink link='Outside Wood' />
        <ProductLink link='Plywood' />
        <ProductLink link='Wood Boards' />
      </div>
      <Figure title='Client Testimonial' />

      <iframe
        height='200'
        src='https://www.youtube.com/embed/W-FDNNyk4Y0?si=ZL_AMNZMIqidB0Cp'
        title='YouTube video player'
        allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
        className='py-5 w-full'
      ></iframe>

      <Figure title='Top rated products' />
      <div className='top-rated px-2 text-sm py-4'>
        {topProduct?.slice(0, 5)?.map((item, index) => (
          <TopRated
            key={index}
            title={item.name}
            src={item.images[0]}
            alt={'wood'}
            item={item}
          />
        ))}
        {/* <TopRated title={title1} src={src1} alt={alt1} />
        <TopRated title={title2} src={src2} alt={alt2} />
        <TopRated title={title3} src={src3} alt={alt3} />
        <TopRated title={title4} src={src4} alt={alt4} />
        <TopRated title={title5} src={src5} alt={alt5} /> */}
      </div>
      <Figure title='Accept Payment Methods' />
      <div className='payment flex flex-wrap py-4 gap-1'>
        <img src={pay1} alt='Virement Bancaire' />
        <img src={pay2} alt='MoneyGram' />
        <img src={pay3} alt='PayPal' />
      </div>

      <iframe
        width='auto'
        height='200'
        src='https://www.youtube.com/embed/G2FkVu6awow?si=VwPvP8WeM0_FJuLi'
        title='YouTube video player'
        allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
        className='pt-6 w-full'
      ></iframe>
    </div>
  )
}

export default Article
