import Ekop1 from '../assets/home/African-Ekop-Naga-Logs-100x100.jpg'
import Ekop2 from '../assets/home/African-Ekop-Naga-Logs-150x150.jpg'
import Ekop3 from '../assets/home/African-Ekop-Naga-Logs-300x300.jpg'
import Pachy1 from '../assets/home/pachyloba-wood-logs-100x100.jpeg'
import Pachy2 from '../assets/home/pachyloba-wood-logs-150x150.jpeg'
import Pachy3 from '../assets/home/pachyloba-woodd.jpg'
import Teak1 from '../assets/home/Teak-Wood-Logs-100x100.jpg'
import Teak2 from '../assets/home/Teak-Wood-Logs-150x150.jpg'
import Teak3 from '../assets/home/Teak-Wood-Logs-300x300.jpg'
import Sapele1 from '../assets/home/Sapele-Wood-Logs-570x427-100x100.jpeg'
import Sapele2 from '../assets/home/Sapele-Wood-Logs-570x427-150x150.jpeg'
import Sapele3 from '../assets/home/sapele-woodd.jpg'
import Padauk1 from '../assets/home/Padauk-Wood-Logs2-570x492-100x100.jpg'
import Padauk2 from '../assets/home/Padauk-Wood-Logs2-570x492-150x150.jpg'
import Padauk3 from '../assets/home/Padauk-Wood-Logs2-570x492-300x300.jpg'
import Mahogany1 from '../assets/home/Mahogany-Wood-Logs-570x421-100x100.jpg'
import Mahogany2 from '../assets/home/Mahogany-Wood-Logs-570x421-150x150.jpg'
import Mahogany3 from '../assets/home/Mahogany-Wood-Logs-570x421-300x300.jpg'
import Iroko1 from '../assets/home/Iroko-Wood-Logs-570x428-300x300.jpg'
import Doussie1 from '../assets/home/doussie-timber.jpg'
import ayous from '../assets/home/ayous-wood-slabs.jpg'
import pine from '../assets/home/pine-wood.jpg'
import eucalyptus from '../assets/home/eucalyptus-slabs-wood.jpg'
import ayousLogs from '../assets/home/ayous-wood-logs.jpg'
import pineLogs from '../assets/home/pine-wood-logs.jpg'
// import Bamboo1 from '../assets/home/'
// import Sawn1 from '../assets/home/Sapele-Sawn-Timber-350x350-300x300.jpg'

import ekopNaga from '../assets/home/ekop-naga-300x300.jpg'
import pachylobaWood from '../assets/home/Pachyloba-1-300x300.jpg'
// import teakSawn from '../assets/home/Teak-Sawn-Timber.jpg'
import teakSawn3 from '../assets/home/teak-timber.jpg'
import sapeleSawn from '../assets/home/Sapele-Sawn-Timber-350x350-300x300.jpg'
import padaukSawn from '../assets/home/Padauk-Sawn-Lumber-570x380-300x300.jpg'
import mahoganySawn from '../assets/home/Mahogany-Sawn-Timber-570x405-300x300.jpg'
import irokoSawn from '../assets/home/Iroko-Sawn-Timber-300x300.jpg'
import doussieSawn from '../assets/home/Doussie-Sawn-Timber-300x300.jpg'

import anigre from '../assets/home/Anigre-sawn-timber--500x500.jpeg'
import wood from '../assets/home/wood1.jpg'
import birch from '../assets/home/birch-wood.jpeg'
import wenge from '../assets/home/Wenge-Sawn-Timber-570x428.jpeg'
import afromosia from '../assets/home/Afromosia-Industrial-Parquet-600x337.jpeg'
import ash from '../assets/home/ash-timber.jpeg'
import balsa from '../assets/home/Balsa-timber-Wood-768x253.jpeg'
// import laminated from '../assets/home/laminated-chipboard-300x261.jpeg'
import laminate1 from '../assets/home/laminated-chipboard.jpeg'
import railway from '../assets/home/railway-wooden-sleepers-600x364.jpg.webp'
import bubinga from '../assets/home/bubinga-slab-300x215.jpeg'
import inshell from '../assets/home/Inshell-Walnut-600x400.jpeg'
import walnut from '../assets/home/walnut-kernels-300x300.jpeg'
import bamboo from '../assets/home/bamboo-wood-278x300.jpg.webp'
import ekopbeli from '../assets/home/ekop-beli-768x356.jpeg'

export const products = [
  {
    category: 'African Round Wood Log Species',
    name: 'Ekop Naga',
    url: 'ekop-naga',
    description: {
      p1: 'Ekop-Naga with the Latin name Brachystegia leonensis is an African wood species commonly harvested in the coastal region of south-east Cameroon. It is also called Naga in some regions, the trees can grow up to 18 m and have a trunk diameter of up to 1.5 m.',
      p2: 'With density 560 (Kg/m3), 12.88 GPa Elastic Modules, and 55 Mpa Crushing Strength Ekop-Naga is a great choice in interiors joinery, Interior paneling, furniture or furniture components, frames, stairs (inside), parquet floors, Light carpentry, Glued laminated, and Cabinetwork (high-class furniture).',
      p3: 'If we talk about physical appearance, then the sapwood of the Ekop-Naga is white to pale yellowish and the heartwood is pale brown to copper brown and clearly demarcated. It is easy to saw Ecop-Naga when it is green, but after drying in the air, much more force is required and burn may also occur. The bole of the tree will be short and often curved and it features large buttresses or rounded ridges at the base.',
      p4: 'The wood features highly interlocked grain thus special care is required when drying due to the frequent risks of distortion. Ekop-Naga is Moderately resistant to termites attack and Moderately durable to decay. However, without preservative treatment, it will be at risk of occasional re-humidification.',
      p5: 'There is no commercial restriction on Ekop-naga woods. While the Ekop-Naga is common in dense rain forests or coastal areas the wood is local to Cameroon but also found in other regions such as Gabon with the name Mendou.',
      p6: 'Other than Naga, Ekop-Naga and Mendou, The tree is also popular with a few more names such as Bodgei (Sierra Leone); Ekop; Brown teak; Okwen (United Kingdom); Okwen (Nigeria); Meblo (Côte d`Ivoire); and Tebako in Liberia.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail: `Are you searching for a reputable supllier of top Ekop-Naga
                round logs in your country ? You finally reached the right
                place. Hurry Up! Place your orders from Global Wood Market SA
                . Fill in a few details in the form below and send your
                enquiries along with preferred size and measurements.`,
    images: [Ekop1, Ekop2, Ekop3],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Pachyloba Wood',
    url: 'pachyloba-wood-log-species',
    description: {
      p1: 'Pachyloba Wood is an African wood species in the Afzelia genus. It has characteristics similar to Afzelia wood. The major origin and distribution are limited to Cameroon. In terms of rot resistance, the wood is classified as the best, while it has poor termite/insect resistance.',
      p2: 'Pachyloba, similar to Afzelia, is usable in a variety of wood projects such as furniture making, boat building, cabinetry, veneer, flooring, docks, turned objects, and exterior millwork and construction projects.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'At Global Wood Market SA , we can provide top-quality Pachyloba sawn timber in all standard sizes and dimensions. You can place an order for any quantity of timber, small or large, to be delivered anywhere in the world. To know more or to place your order now, fill-up the form below to send your enquiries.',
    images: [Pachy1, Pachy2, Pachy3],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Teak Wood',
    url: 'teak-wood',
    description: {
      p1: 'Teak wood, also recognized as Burmese Teak, emerges as a paragon of natural sophistication from the heart of southern Asia and Africa. This botanical treasure, scientifically known as Tectona grandis, commands attention with its majestic stature—towering to heights of 100 to 130 feet—and a trunk that bears witness to time, measuring 1 to 1.15 meters in diameter. Also, the Janka hardness rating for teak wood is approximately 1,070 lbf (4,750 N) for its heartwood. ',
      p2: 'Yet, it’s not just its size that captivates; it’s the radiant hues that paint its grains, ranging from the precious golden tones of dawn to the warm mid-browns of a tranquil forest. The allure of teak lumber extends beyond aesthetics, as its intrinsic properties encompass a remarkable resilience, defending against nature’s forces, whether in the form of voracious insects or the relentless march of decay.',
      p3: 'From the heartwood of these mighty giants arises a legacy of utility and elegance. This legacy is etched into the annals of craftsmanship, where teak’s eminence is hewn into graceful forms. Furniture artisans fashion it into heirloom-quality tables and chairs that merge functionality with timeless beauty. The maritime world, too, pays homage to teak’s virtues, as shipbuilders entrust their vessels’ foundations to its sturdy embrace, weathering the tempestuous seas with unwavering poise. ',
      p4: 'But its influence reaches beyond mere utility—it graces spaces with durable outdoor furniture such as decks and gazebos, its presence a testament to nature’s harmony coaxed into structures that stand in silent companionship with the environment. Yet, teak is more than its earthly embodiment; it symbolizes the delicate balance between humanity’s ingenuity and nature’s magnificence, a dialogue of utility and artistry, strength and elegance, standing as a sentinel of sustainable reverence for the world’s forests.',
      p5: 'Global Wood Market SA  is one of the top and best Teak wood suppliers, wholesaler and exporter worldwide. Contact us now for more details about our Teak Wood quality, Specification, Grade, Measurements, etc., by filling up the form below. Or simply email your queries or requirements to us at support@globalwoodmarketsa.com.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'At Global Wood Market SA , we can provide top-quality Teak Wood sawn timber in all standard sizes and dimensions. You can place an order for any quantity of timber, small or large, to be delivered anywhere in the world. To know more or to place your order now, fill-up the form below to send your enquiries.',
    images: [Teak1, Teak2, Teak3],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Sapele Wood',
    url: 'sapele-wood',
    description: {
      p1: 'Entandrophragma cylindricum, commonly known as Sapele, is a Mahogany species wood timber. The species originates in tropical Africa. Sapele trees is known for their dimensions as they can go as high as up to 45 meters and up to 10m in diameter.',
      p2: 'At Global Wood Market  we are exporting the high-quality Sapele wood at your doorsteps at wholesale prices. Contact us now for more details about our Sapele Wood quality, Specification, Grade, Measurements, etc., by filling up the form below. Or simply email your queries or requirements to us at support@globalwoodmarketsa.com.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [Sapele1, Sapele2, Sapele3],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Padauk Wood',
    url: 'padauk-wood',
    description: {
      p1: 'It is also a Pterocarpus species wood that is also commonly known as African Padauk or padauk lumber. The hardwood colour ranges from bright orange to brown red, depending on the origin. The Padauk tree height is usually 100-130ft and trunk diameter ranges between 2 to 4 ft. The wood has a high janka hardness, making it a popular choice for flooring and furniture.',
      p2: 'At Global Wood Market SA , we deliver all varieties of Padauk wood logs in all standard and custom measurements. Contact us now for more details about our Padauk Wood quality, Specification, Grade, Measurements, etc., by filling up the form below. ',
      p3: 'You can also email your queries or requirements to us at support@globalwoodmarketsa.com Our Padauk wood logs are known for their durability, making them a popular choice for various construction and furniture projects.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [Padauk1, Padauk2, Padauk3],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Mahogany Wood',
    url: 'mahogany-wood',
    description: {
      p1: 'Mahogany is a straight-grained wood that is found in multiple varieties. The natural distribution of Mahogany trees is limited to certain geographic locations including Americas, Peru, Bolivia, etc. The wood is reddish-brown in color and primarily used for making furniture and cabinets.',
      p2: 'We are one of the top Mahogany Wood suppliers, exporters, wholesalers in Cameroon wood market. Contact Global Wood Market SA now for more details about our Mahogany Wood quality, Specification, Grade, Measurements, etc., by filling up the form below. Or simply email your queries or requirements to us at support@globalwoodmarketsa.com or call us at +237 695268508',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Get natural mahogany wood logs at best price with free shipping facility only at Global Wood Market. Contact us today!',
    images: [Mahogany1, Mahogany2, Mahogany3],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Iroko Wood Logs',
    url: 'iroko-wood',
    description: {
      p1: 'Iroko is derived from a hardwood tree of the same name. It is usually found on the coast of Africa. The interesting is the large structure and long life (up to 500 years) of these trees. The iroko wood is yellow in colour which gets dark brown over time.',
      p2: 'Global Wood Market SA is one of the top Iroko Wood Suppliers in Douala, Cameroon.  Contact us now for more details about our Iroko Wood logs price, quality, Specification, Grade, Measurements, etc., by filling up the form below. Or simply email your queries or requirements to us at support@globalwoodmarketsa.com',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [Iroko1, Iroko1, Iroko1],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Doussie Wood Logs (Afzelia)',
    url: 'doussie-wood',
    description: {
      p1: 'Doussie Wood Logs Also known as Afzelia, it is a reddish brown hardwood. The distribution of Doussie is mostly limited to Southeast Asia and Africa. The grains are interlocked which make it difficult to work with it.',
      p2: 'At Global Wood Market SA we are one of the leading Doussie Wood logs Suppliers in Douala offering reddish brown Doussie Wood to our customers.',
      p3: 'Contact Global Wood Market SA now for more details about our Doussie Wood quality, Specification, Grade, Measurements, etc., by filling up the form below. Or simply email your queries or requirements to us at support@globalwoodmarketsa.com',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [Doussie1, Doussie1, Doussie1],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Bamboo',
    url: 'bamboo',
    description: {
      p1: 'Commercial bamboo lumber is obtained from a grass species called giant bamboo Moso. The wood can be harvested only after it is fully matured to ensure the it is strong enough. Bamboo is split to make lumber for commercial use. It is strong, moderately heavy and has basic durability. The best thing about bamboo is that it is easily available, inexpensive and easy to work with and maintain. Moreover, bamboo is lighter than many hardwoods, which makes it easy to handle and transport. It is a strong and can be used in construction applications as well as for making furniture and other interior items. Other uses of bamboo include flooring, house construction, bridges, roofs, falls, and scaffolding.',
      p2: 'Even though it’s not wood, bamboo has many wood-like properties. It looks like wood, is strong and hard and can be turned into a variety of objects ranging from furniture to ladders, fishing rods, and even musical instruments.',
      p3: 'Now you can buy high-quality bamboo lumber online from the comfort of your home. Just open the website globalwoodmarketsa and search for bamboo. To place your order or enquire about availability and price in your location, you can call us or drop an email to info@globalwoodmarketsa.com.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Bamboo isn’t technically wood but is a part of the Poaceae (grass) family. Timber-producing bamboo trees are commonly found in South Asia and are a common source of commercial timber often used as a cost-effective alternative to expensive hardwoods like teak. Depending on the species, bamboo can be moderately hard and heavy. The timber is light yellow or white and almost non-durable in terms of resistance to decay and insect attacks. It will start deteriorating within a few years due to attacks by fungi and mold.',
    images: [bamboo, bamboo, bamboo],
  },

  {
    category: 'Outside Wood',
    name: 'Inshell Walnuts',
    url: 'inshell-walnuts',
    description: {
      p1: 'Walnuts are full of nutritional value and an ideal source of a variety of nutrients, vitamins and minerals, including omega-3 fatty acids and antioxidants. In fact, they are one of the best natural sources of antioxidants. On top of that, Inshell walnuts are super delicious and can be added to recipes to add extra taste and nutritional value. Walnuts can also be eaten as snacks in raw or cooked form.',
      p2: 'Buy the best quality walnuts in-shell from Global Wood Market SA. Our walnuts are sourced from local farmers who produce them during the season in their orchards with great care. We have in-shell walnuts available year-round, and we provide full assurance of the best quality and price in the market. We at Global Wood Market SA ship inshell walnuts  to our customers and merchants all over the world, including Canada, Australia, UAE, Malaysia, Vietnam, Iraq, Indonesia, Brazil, and more. All shipments are handled carefully by our in-house delivery system and will be delivered to your location by the promised date.',
      p3: 'We bring the finest quality walnuts in multiple sizes (broken, half and quarter) for you. All products are securely packed in sustainable packages and will be shipped to your location anywhere in the world (contact us for shipping enquiry).',
      p4: 'Need help choosing the best walnut variety? All walnut types we sell are of premium quality and sustainably produced and sourced. If you want to know more about our walnut varieties or need help choosing the best product, feel free to contact us. We are very careful about protecting the environment and pay well to our suppliers and partners, which results in the highest quality products that are both good and affordable.',
      p5: 'We normally sell in bulk but are also accepting smaller orders or 1 kg and more. A range of packaging options is available, including plastic bags and paper bags, which you can choose from based on your taste. Smaller or extra large packages can also be made available. There is no upper limit to how much quantity of inshell walnuts you can order. Buy inshell walnuts today or contact us to enquire about availability, quality and price.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [inshell, inshell, inshell],
  },
  {
    category: 'Outside Wood',
    name: 'Walnuts Kernel',
    url: 'walnuts-kernel',
    description: {
      p1: 'Walnut Kernels are the inside parts or edible seeds of walnut, a drupe fruit that has a strong outer shell and soft inside seeds that are edible. These walnut seeds are called kernels and have amazing nutritional properties. They are an excellent source of antioxidants, omega-3s, protein, fiber and many vitamins and minerals. Buy premium quality walnut kernels in different grades at the best affordable price online from Global Wood Market SA',
      p2: 'We source the best quality walnut kernels from sustainably harvested walnut trees around the world. Our walnuts are full of rich qualities and nutrients and will give the best results whatever purpose you are planning to use it for. We ship to all major countries and cities, including Canada, Australia, UAE, Malaysia, Vietnam, Iraq, Indonesia, Brazil, and more.',
      p3: 'Walnuts are of different types based on their origin, color, size and other factors. When selecting loose walnut kernels, go for the ones that are brown or amber-colored. Cracked and discolored walnuts are not generally of the best quality.',
      p4: 'The kernels come in different forms such as whole, half, quarter, broken, minced, etc. You can choose them according to your specific requirement and how you need them. Broken walnuts are generally less expensive than whole.',
      p5: 'If you are looking to buy walnut kernels in bulk, you can contact us for the price. For retail orders, the price of walnut kernels is mentioned on the page. You can place your order directly from our website or contact us to place an order on call. If you are finding it difficult to choose the right walnut variety or have questions about our kernels quality, size and availability, feel free to reach us at info@globalwoodmarketsa.com.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [walnut, walnut, walnut],
  },
  {
    category: 'Wood Boards',
    name: 'Laminated Chipboard',
    url: 'laminated-chipboard',
    description: {
      p1: 'Looking to buy the best quality laminated chipboard for a project? We at Global Wood Market SA sell premium grade chipboard at the best price in the market. We supply and export to all major locations around the world and take full guarantee of the quality and price of our products. Contact us to know more.',
      p2: 'Chipboard, also commonly known as particleboard, is an engineered wood board made by pressing wood chips together with a type of glue called Urea Formaldehyde (UF). The UF glue is compressed together with chipboards under high pressure and heat conditions to form a wood panel.',
      p3: 'Chipboards made with UF glue are not resistant to moisture and not particularly useful in kitchens and outdoor conditions. For such moisture-intense uses, MUF chipboards are ideal, as they are made with Melamine Urea Formaldehyde glue and are resistant to moisture (but not water).',
      p4: 'Laminated chipboards are generally cheaper than solid woods and can be as strong or sometimes stronger. They are easy to use and widely available. Laminated chipboard is made by covering chipboards with a paper-based melamine resin film under high temperature and pressure that form a lamination/film on the chipboard surface.',
      p5: 'Chipboard comes in different varieties, each having different properties. High-density chipboards are stronger and more durable than normal chipboards. MUF chipboards are somewhat moisture resistant. Laminated chipboards are even stronger and more durable than regular chipboards. Best of all, they are cheaper than any solid wood and are perfect for any low-cost woodworking project.',
      p6: 'If you are looking for a cheaper and good quality wood alternative for personal use or selling, laminated chipboard is the best choice for you. For the best price of laminated wood anywhere in the world, contact Global Wood Market SA .',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [laminate1, laminate1, laminate1],
  },
  {
    category: 'Wood Boards',
    name: 'RAILWAY WOODEN SLEEPERS',
    url: 'railway-wooden-sleepers',
    description: {
      p1: 'Looking for the best quality railway sleepers for your garden or railway line project without breaking the bank? Our durable and stylish wooden railway sleepers are perfect for creating strong and stable railway tracks, garden paths, and sturdy decks.',
      p2: 'Our sturdy and durable wooden sleepers will make sure to maintain the rail gauge (distance between tracks), providing stability and shock resistance and ensuring a highly safe railway line system.',
      p3: 'You can also use these weather-resistant railway wooden sleepers to transform your gardens or outdoor space, blending strength with affordability. Explore our collection here and contact us to get the best price quote for your requirements.',
      p4: 'They are rectangular slabs, usually made of wood, that are commonly used for maintaining the rail gauge effectively. Wood is among the best materials for railway sleepers because of its natural durability, stability, and shock resistance, which allow these sleepers to absorb shocks and vibrations, ensuring a stable ride.',
      p5: 'Both softwoods and hardwoods are used for building sleepers. Hardwoods like oak, iroko, mahogany and beech are the most popular choices, while softwoods such as pine are also commonly used.',
      p6: 'They are primarily used in building railway tracks. They are placed between two rail tracks to maintain the correct space of the gauge. Other than that, wooden sleepers are also nowadays used for landscaping purposes, in garden projects such as raised beds, pathways, steps, etc.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'To start with, determine the type of wood you want for your sleepers. While hardwood options like oak offer durability, softwoods like pine are more budget-friendly. Consider your budget and particular project requirements. Additionally, check the supplier’s reputation and buy railway sleepers only from a credible wood seller.',
    images: [railway, railway, railway],
  },

  {
    category: 'African Sawn Timber',
    name: 'Ekop-Naga Timber',
    url: 'ekop-naga-timber',
    description: {
      p1: 'Ekop-Naga is one of the lesser-known African timber species that is primarily grown in the coastal region of south-east Cameroon. Its scientific name is Brachystegia leonensis and is exclusively found in Africa.',
      p2: 'The physical characteristics of Ekop-Naga include white to pale yellow color for sapwood and pale fawn to dark brown color for heartwood. It’s easier to work with when fresh and gets stronger as dried, making it difficult to saw. Ekop-Naga timber is produced from a tree that is usually 18 m tall and has a trunk diameter ranging from 0.7 m to 1.2 m.',
      p3: 'Common applications & uses of Ekop-Naga timber include interior woodwork projects such as flooring, furniture making, joinery, panelling, stairs, frames and parquet floors.',
      p4: 'Now, you can buy the top-quality African Ekop-Naga timber from Global Wood Market at very nominal market price. We deliver Ekop-Naga sawn timber to customers in many countries, including UAE, Malaysia, Vietnam, Iraq, Canada, Saudi Arabia, and others.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Ekop-Naga is one of the lesser-known African timber species that is primarily grown in the coastal region of south-east Cameroon. Its scientific name is Brachystegia leonensis and is exclusively found in Africa.',
    images: [ekopNaga, ekopNaga, ekopNaga],
  },
  {
    category: 'African Sawn Timber',
    name: 'Pachyloba Wood',
    url: 'pachyloba-Wood',
    description: {
      p1: 'Pachyloba is an African wood species of Afzelia class. Commonly known as white afzelia, it is an economic species of a tropical forest tree. The wood is local to Cameroon but also found in other regions of Western and Central Africa.',
      p2: 'Because of its rare availability, Pachyloba or afzelia pachyloba can be expensive to buy. The price would also depend on the quality of wood and the buyer. To find out the best price of original African Pachyloba, contact Global Wood Market SA, a leading supplier & distributor of top-grade Africa wood timber and logs.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Searching for a reliable supplier of top quality Pachyloba Arican round logs in your country? Your search ends here. To order afzelia pachyloba from Global Wood Market SA , use the form below to send your enquiries along with preferred size and measurements.',
    images: [pachylobaWood, pachylobaWood, pachylobaWood],
  },
  {
    category: 'African Sawn Timber',
    name: 'Teak Timber',
    url: 'teak-timber',
    description: {
      p1: 'Found primarily in the jungles of southern Asia and Africa, Teak or Burmese Teak timber is a golden to mid-brown colored wood species of Tectona grandis family. It is produced from a tree that goes about 100 to 130 ft high and 1 to 1.15 meter in trunk diameter.',
      p2: 'Interested in buying Teak Timber from the world’s top timber exporter  and supplier? Send your enquiries now using the form below. Want to know more about our Teak Wood quality, Specification, Grade, Measurements, etc? Fill up the form and we will get back shortly.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [teakSawn3, teakSawn3, teakSawn3],
  },
  {
    category: 'African Sawn Timber',
    name: 'Sapele Timber',
    url: 'sapele-timber',
    description: {
      p1: 'Sapele is one of the most popular African wood species. It is grown and exported majorly for economic purposes and used in furniture, flooring, plywood, musical instruments and more. The Sapele trees grow in tropical Africa with the usual height being around 100-150 ft height and trunk diameter around 3-5 ft.',
      p2: 'Looking for an affordable option to purchase quality Sapele Timber in your budget. Check out product options at Global Wood Market SA ? Use the form below to send your queries or about our Sapele timber quality, Specification, Grade, Measurements, etc.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [sapeleSawn, sapeleSawn, sapeleSawn],
  },
  {
    category: 'African Sawn Timber',
    name: 'Padauk Timber',
    url: 'padauk-timber',
    description: {
      p1: 'Padauk, African Padauk, or vermillion, is a heartwood of orange to brown-red color. It is primarily found in Central and tropical west Africa jungles. The straight grain texture of Padauk timber makes it easy to work with. Common uses include flooring, music instruments, veneer, furniture, etc.',
      p2: 'Looking to buy quality and certified Padauk Sawn Timber? Check out the quality, Specification, Grade, exporting and supplying services and Measurements of our Padauk timber at Global Wood Market SA . Send a query using the form below.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [padaukSawn, padaukSawn, padaukSawn],
  },
  {
    category: 'African Sawn Timber',
    name: 'Mahogany Timber',
    url: 'mahogany-timber',
    description: {
      p1: 'Mahogany Timber is one of the most popular wood species for making general purpose furniture, cabinets, etc. It is available in multiple varieties and has a straight-grained texture. The wood is reddish-brown in color and derived from trees found in Americas, Peru, Bolivia, etc.',
      p2: 'Mahogany timber is an ideal choice of wood when it comes to furniture making. Because of its dense and beautiful structure, it is also used as veneer sheets for making plywood. Some other uses of mahogany timber include flooring, boat-building, home decor, etc.',
      p3: 'If you interested in buying Mahogany from Global Wood Market SA , use the form below to send your enquiries and details about Mahogany Timber Specification, Grade, Measurements, etc. We will contact you back shortly. We are exporting and supplying mahogany wood at wholesale prices.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [mahoganySawn, mahoganySawn, mahoganySawn],
  },
  {
    category: 'African Sawn Timber',
    name: 'Iroko Timber',
    url: 'iroko-timber',
    description: {
      p1: 'Iroko Timber is a hardwood produced from a tree that generally grows on the coast of Africa. These trees, which live as long as 500 years, feature a very large structure and wide trunk. The Iroko wood is easy to work with except for surfacing operation which may be difficult because of its interlocked grains.',
      p2: 'Want to buy or check out at Global Wood Market SA? Use the form below to send your requirements or queries about the quality, Specification, Grade, Measurements, exporting & supplying service etc of our Iroko Wood.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [irokoSawn, irokoSawn, irokoSawn],
  },
  {
    category: 'African Sawn Timber',
    name: 'Doussie Timber',
    url: 'doussie-timber',
    description: {
      p1: 'Doussie Timber heartwood is usually red-brown in color and found in some parts of Africa and Southeast Asia. The wood is also commonly known for its other name – Afzelia. The interlocked grains of Doussie wood make it strong but difficult for cutting work.',
      p2: 'Looking to buy top-quality Doussie Sawn Timber from Global Wood Market SA? Contact us now using the form below. Send your requirements or queries to know more about our Doussie Wood quality, Specification, Grade, Measurements, etc.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [doussieSawn, doussieSawn, doussieSawn],
  },

  {
    category: 'African Sawn Timber',
    name: 'Afromosia Timber',
    url: 'afromosia-timber',
    description: {
      p1: 'Afromosia Timber Also sometimes called by its scientific name Pericopsis elata, Afromosia or African Teak is a yellow color wood mostly found in West Africa. The tree of Afromosia goes as high as up to 150 feet while the trunk is usually 3 to 5 ft in diameter.',
      p2: 'At Global Wood Market SA , you can find, select and order the best quality Afromosia sawn timber in town to be used for any type of furniture work.',
      p3: 'Interested in buying Afromosia Sawn Timber from Global Wood Market? Use the form below to send your queries or orders or to know more about our Afromosia Wood quality, Specification, Grade, Measurements, etc.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [afromosia, afromosia, afromosia],
  },
  {
    category: 'African Sawn Timber',
    name: 'Ash Timber',
    url: 'ash-timber',
    description: {
      p1: 'White Ash is also called American white ash because it is primarily found in Eastern North America. Fraxinus Americana is the scientific name for ash lumber. The trees are about 20-30 m tall and have a 6-1.5 m trunk diameter. It is heavy in terms of dried weight and has a high Janka rating of 1,320 lbf (5,870 N), which also makes it very hard.',
      p2: 'The ash heartwood is light brown while the sapwood is whitish brown or beige and not always distinguishable from the heartwood. The wood texture is medium to coarse and the grain is straight, though the presence of figured timber is not so uncommon. Ash is a non-durable wood with perishable heartwood. It is non-resistant to insect attack but can take decay. Also, white ash timber has amazing shock resistance. Ash is particularly easy to work with both machine and hand tools. It glues, bends (steam bending), finishes, and stains well. Staining ash can make it look similar to oak.',
      p3: 'Because ash is easily available and inexpensively priced, it is used in many applications, including the construction of flooring and furniture, millwork, boxes, crates, interior joinery, turned objects such as tool handles, and baseball bats.',
      p4: 'Looking to buy industrial-grade ash timber at the best price online with great discounts and offers? Contact us or visit Global Wood Market SA  to order cut-to-size ash lumber today and get doorstep delivery anywhere in the world within a month or so. All our wood is sustainably harvested and carefully processed by expert woodworkers for the best quality for our customers.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Ash is a popular American hardwood timber that is commonly called White Ash because of its light pale bark. Despite being an inexpensively priced timber, Ash is quite strong and hard, though it is not durable. Ash timber is used in a variety of applications, from flooring and millwork to boxes, crates, tool handles, and baseball bats.',
    images: [ash, ash, ash],
  },
  {
    category: 'African Wood Slabs',
    name: 'Bubinga slabs',
    url: 'bubinga-slabs',
    description: {
      p1: 'Looking for the best place to buy cheap Bubinga hardwood slabs? Welcome to Global Wood Market SA – a leading manufacturer & supplier of industry-grade wood logs, timber and slabs. We deliver top-quality wood slabs to all over the world, including countries like Canada, UAE, Vietnam, Iraq, Malaysia, Saudi Arabia, and Australia.',
      p2: 'Bubinga is an African hardwood that is commonly found in Equatorial Africa. The Bubinga tree has a height of 130-150 ft and a trunk diameter of 3-6 ft. The wood color ranges from pink-red to dark brown with figures like streaks, waterfall, flames, etc. appearing on it. It is usually very durable and has strong resistance to termites and moisture. Common applications of Bubinga hardwood include fine furniture making, veneer making, cabinetry, etc. Bubinga slabs, both natural-edge and live-edge, are widely used in tabletops and other speciality furniture projects.',
      p3: 'You can choose from a variety of board types (live edge, rough edge, square edge, edge-glued, etc.), grain types (standard, burl, crotch, figured, etc.), and related services such as sanding, finishing, and planing. Our Bubinga wood slabs are available in a wide range of customised sizes and shapes. So, you can order Bubinga slabs in any size, length, width or thickness as you want.',
      p4: 'Our Bubinga wood slabs come in ready to use form and are ideal to be used in a range of woodworking projects including table tops, bar tops, stools, kitchen tables, doors, shelves, end tables, wall art, benches, and many more.',
      p5: 'To place your order now or to know more about our Bubinga slabs specifications, dimensions, quality, grade, price, etc., call our sales team at +237 695 26 85 08 or drop an email to info@globalwoodmarketsa.com',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Buy the best quality Bubinga hardwood slabs at affordable rates directly from Global Wood Market SA and get delivery to your location anywhere in the world.',
    images: [bubinga, bubinga, bubinga],
  },
  {
    category: 'African Sawn Timber',
    name: 'Balsa Timber',
    url: 'balsa-timber',
    description: {
      p1: 'Looking for the best place to buy high-quality Balsa timber? Connect with Global Wood Market SA  to enquire about the best price for timber. All our wood is sustainably sourced from legit forests and plantations and we take full guarantee of quality and the best rate in the market.',
      p2: 'Balsa is a hardwood found in American jungles. The sawn timber extracted from Balsa trees is not strong or durable. The Balsa sawn lumber has zero resistance to insects. It is commonly referred to as the softest commercial hardwood. It is not commonly seen in commercial uses, but is good for making other specialty things like surfboards, boats, buoys, rafts, musical instruments, model airplanes, packing cases, among others. The wood can be moderately to expensively priced depending on the availability.',
      p3: 'To place your timber order, you can call us at +237 695 268 508 or visit our website to send your requirements. We are online 24/7, ready to help you with all your queries. If you want advice or need help choosing the right wood for your project, feel free to call us at any time. Also, be sure to explore our website to know about a large variety of lumber both locally produced (in Africa) and imported and know more about our wood services and products. Buy Balsa wood now!',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'We can provide Balsa timber in all required sizes and dimensions and safely deliver it to your location in almost every country in the world. Contact us today to discuss further.',
    images: [balsa, balsa, balsa],
  },
  {
    category: 'African Sawn Timber',
    name: 'Anigre Wood',
    url: 'anigre-wood',
    description: {
      p1: 'Anigre timber wood, also commonly referred to as Anegre and Aniegre, is an African tropical hardwood that is commonly found in tropical areas of East Africa. The Anigre tree is typically 100-180 ft (30-55 m) tall and has a 3-4 ft (1.0-1.2 m) trunk diameter. The heartwood is light yellowish-brown in color, which may turn to golden brown with age.',
      p2: 'Due to its non-durable and non-resistant rating, Anigre is not largely suitable for outdoor wood projects. But, it’s widely used as veneer and for interior furniture and plywood construction. Also, boat building and carpentry using Anigre timber are not uncommon.',
      p3: 'At Global Wood Market SA we are dealing with all types of exporting services and become one of the top class Anigre Wood Suppliers.',
      p4: 'Contact us now for more details about our Anigre Timber Wood quality, Specification, Grade, Measurements, etc., by filling up the form below. Or simply email your queries or requirements to us at support@globalwoodmarketsa.com or call us at +237695268508',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'If you are looking to buy the best quality Anigre African Timber in standard or custom sizes, we’d be glad to listen to your requirements and deliver top-standard timber to you. ',
    images: [anigre, anigre, anigre],
  },
  {
    category: 'Wood Boards',
    name: 'Wood Chips',
    url: 'wood-chips',
    description: {
      p1: 'Wood chips are small or mid-sized wood pieces that are formed by cutting wood slabs or logs into smaller pieces generally using a machine called a wood chipper.  The chips are used for a variety of purposes ranging from wooden pulp to biomass fuel, organic mulch, playground surfacing, bioreactor and others.',
      p2: 'To buy best quality wood chips at the lowest price in the market, you can contact Global Wood Market SA. We are a leading supplier of premium grade wood logs, timber, slabs, chips, laminated boards, plywoods and more. With over 30 years of experience in the timber industry, we are trusted for quality and credibility. We have the biggest community of timber producers, suppliers and dealers in Central Africa and can consistently deliver the best quality wood at the lowest price to your doorstep anywhere in the world.',
      p3: 'Basically, wood chip can be produced from any kind of wood of any tree. However, the quality of  chips will greatly depend on the type of lumber used. Softwoods are generally less dense and grow faster, which makes them the first choice for making chips.',
      p4: 'They are used for a variety of purposes ranging from wood pulp, mulch, playground surfacing, barbecuing, bioreactor, and fuel. Common sources for wooden chips include cedar, pine, cypress, spruce, straw, etc.',
      p5: 'If you are looking for the best place to buy premium quality chips at the lowest price online, look no further. Global Wood Market SA produces and sells wood chip in a large variety and can deliver them to your doorstep anywhere in the world. We have large-sized wood chipper machines at our mill and produce all types of chips from residues to forest, sawmill chips and others.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'If wood chips are so successful today, it is certainly not by chance.  They differ from their competitors (pine bark, clay pebbles, hessian…) by their many advantages.',
    images: [wood, wood, wood],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Birch Wood',
    url: 'birch-wood',
    description: {
      p1: 'Yellow Birch is obtained from a deciduous tree which is native to Northeastern North America and has a 65-100 ft (20-30 m) height and a 2-3 ft (.6-1.0 m) trunk diameter. The wood is moderately heavy and hard with a Janka score of 1,260 lbf and an average dried weight of 690 kg/m3. It is most commonly used for veneer and plywood. Other uses include paneling, doors, furniture, boxes, paper, turned objects, crates, and interior trim.',
      p2: 'Yellow Birch has a light reddish brown heartwood with occasional figured grain patterns such as shallow curls. The grain is straight or sometimes wavy and the texture is fine and even. Birch is a non-durable hardwood and cannot resist moisture and insects at all. It is generally easy to work with and turns, glues, and finishes well. The cost depends on the availability, but it’s generally considered an economical hardwood.',
      p3: 'Looking to purchase birch wood in bulk? Contact Global Wood Market SA for the best price and offers. We have birch timber available in various dimensions and sizes. All our wood is sustainably sourced from responsibly managed forests and plantations. We provide doorstep delivery all over the world with a guarantee of quality and satisfaction. Contact us to tell us your requirements and preferred timber size and quantity to get the best quote.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail: `Birch hardwood comes from the same family as Alder wood (Betulaceae) and belongs to the genus Betula. There are more than 30 known species of Birch. It is a rather soft hardwood which is commonly used in the paper industry and also to make toothpicks and toys. Paper Birch, Yellow Birch, River Birch, Downy Birch, Sweet Birch, Gray Birch, Alder-leaf Birch, Masur Birch, and Silver Birch are popular Birch species.
      Contact Us to buy high-quality Birchwood timber, logs and slabs online at the best price.`,
    images: [birch, birch, birch],
  },
  {
    category: 'African Sawn Timber',
    name: 'Wenge Timber',
    url: 'wenge-timber',
    description: {
      p1: 'Wenge is a heartwood species grown in Central Africa. The trees are about 60-90 ft tall with 3-4 ft trunk diameter. The timber wood color ranges from medium brown to reddish with black streaks. The light and dark areas of the same wood differ in density which makes it sand unevenly. Common uses include furniture making, musical instruments, veneer, turned objects, etc.',
      p2: 'Need the best quality Wenge Timber for your next woodwork project. Check out the Wenge Wood quality, Specification, Grade, and Measurements at Global Wood Market SA? Use the form below to send your trade enquiries, or call/Whatsapp +237695268508.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [wenge, wenge, wenge],
  },
  {
    category: 'African Wood Slabs',
    name: 'Ekop Beli Slabs',
    url: 'ekop-beli-slabs',
    description: {
      p1: 'Global Wood Market SA is a leading supplier of top-grade wood slabs in all the top African hardwood species. If you are looking to buy the best quality Ekop beli wood slabs at cheap prices, Global Wood Market SA is your first and only choice. We export our Ekop beli timber and slabs to dealers and businesses all over the world, including top countries in Asia, Europe, and the Middle East. You can contact us to check the latest prices, availability and specifications of our Ekop beli wood slabs.',
      p2: 'Beli is an African native wood, which is harvested primarily in the Gabon and Cameroon regions. The beli trees have an average height of 65-100ft and a maximum trunk diameter of 1.5m. The heartwood of beli is light brown in color with darker stripes throughout, while the sapwood is pale yellow without the stripes. The hardwood is moderately durable and has decent insect resistance capabilities. Common applications include flooring, cabinetry, veneering, and interior millworks such as chair railing, crown molding, interior doors, and wood paneling and window casing.',
      p3: 'Beli slabs are generally used as an affordable alternative to Zebrawood for doors, flooring and other range of purposes. Global Wood Market SA is the best online platform to buy top-quality Ekop beli Hardwood Slabs at the lowest possible rates with doorstep delivery anywhere in the world. We have Ekop beli wood slabs cut into custom sizes and kiln dried to preserve the highest levels of quality and remove any insects from it. You can order Ekop beli slabs in whatever size and dimensions that you want. All our wood timber and slabs are made at our own mill located in Douala, Cameroon and processed with great care. We also supply the wood to our customers in multiple countries around the globe.',
      p4: 'Based on your choice, you can select from rough edge Ekop beli hardwood slabs or planed slabs. All variants are read to work with and can be used for a variety of woodworking projects.',
      p5: 'For all your Ekop beli wood slabs requirements, you can contact us through our website. For enquiries about wood species, quality, measurements and price, feel free to reach us via call or Whatsapp at +237 695 268 508 or drop an email to info@globalwoodmarketsa.com. Make sure to mention your preferred size and dimensions when placing an online order with Global Wood Market SA.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Looking for the best place to buy Ekop beli wood slabs at wholesale prices? Buy today from Global Wood Market SA to get the best price guarantee on our complete range of hardwood slabs.',
    images: [ekopbeli, ekopbeli, ekopbeli],
  },
  {
    category: 'African Sawn Timber',
    name: 'AYOUS WOOD TIMBER',
    url: 'ayous-wood-timber',
    description: {
      p1: 'Ayous wood is light yellow in color, sometimes even creamy white. It is used in carpentry for interior design, for example: the ceilings of Paris-Charles De Gaulle airport, and for the production of moldings, as well as in boxes and for the manufacture of plywood, etc.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'The tropical ayous tree, also often called obeché, abachi, wawa, bado or samba, ayous is a very light, very soft to tender exotic wood.',
    images: [ayous, ayous, ayous],
  },
  {
    category: 'African Sawn Timber',
    name: 'PINE WOOD TIMBER',
    url: 'pine-wood-timber',
    description: {
      p1: 'PIN wood is a natural material from trees used in heating, construction, furniture, the manufacture of tools and artistic objects. Its mode of formation and its microscopic structure explain its remarkable properties.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [pine, pine, pine],
  },
  {
    category: 'African Sawn Timber',
    name: 'EUCALYPTUS WOOD TIMBER',
    url: 'eucalyptus-wood timber',
    description: {
      p1: `Eucalyptus wood is also known as eucalyptus hardwood and is harvested from a family of fast-growing tropical shrubs and trees. It's beautiful, eco-friendly, easy to work with and incredibly practical. It is also less expensive than traditional hardwoods like mahogany, hickory, and cherry.`,
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [eucalyptus, eucalyptus, eucalyptus],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'AYOUS WOOD',
    url: 'ayous-wood',
    description: {
      p1: 'Ayous wood is light yellow in color, sometimes even creamy white. It is used in carpentry for interior design, for example: the ceilings of Paris-Charles De Gaulle airport, and for the production of moldings, as well as in boxes and for the manufacture of plywood, etc.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'The tropical ayous tree, also often called obeché, abachi, wawa, bado or samba, ayous is a very light, very soft to tender exotic wood.',
    images: [ayousLogs, ayousLogs, ayousLogs],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'PINE WOOD',
    url: 'pine-wood',
    description: {
      p1: 'PIN wood is a natural material from trees used in heating, construction, furniture, the manufacture of tools and artistic objects. Its mode of formation and its microscopic structure explain its remarkable properties.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [pineLogs, pineLogs, pineLogs],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'EUCALYPTUS WOOD',
    url: 'eucalyptus-wood',
    description: {
      p1: `Eucalyptus wood is also known as eucalyptus hardwood and is harvested from a family of fast-growing tropical shrubs and trees. It's beautiful, eco-friendly, easy to work with and incredibly practical. It is also less expensive than traditional hardwoods like mahogany, hickory, and cherry.`,
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [eucalyptus, eucalyptus, eucalyptus],
  },
]

export const topProduct = [
  {
    category: 'African Sawn Timber',
    name: 'Anigre Wood',
    url: 'anigre-wood',
    description: {
      p1: 'Anigre timber wood, also commonly referred to as Anegre and Aniegre, is an African tropical hardwood that is commonly found in tropical areas of East Africa. The Anigre tree is typically 100-180 ft (30-55 m) tall and has a 3-4 ft (1.0-1.2 m) trunk diameter. The heartwood is light yellowish-brown in color, which may turn to golden brown with age.',
      p2: 'Due to its non-durable and non-resistant rating, Anigre is not largely suitable for outdoor wood projects. But, it’s widely used as veneer and for interior furniture and plywood construction. Also, boat building and carpentry using Anigre timber are not uncommon.',
      p3: 'At Global Wood Market SA we are dealing with all types of exporting services and become one of the top class Anigre Wood Suppliers.',
      p4: 'Contact us now for more details about our Anigre Timber Wood quality, Specification, Grade, Measurements, etc., by filling up the form below. Or simply email your queries or requirements to us at support@globalwoodmarketsa.com or call us at +237695268508',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'If you are looking to buy the best quality Anigre African Timber in standard or custom sizes, we’d be glad to listen to your requirements and deliver top-standard timber to you. ',
    images: [anigre, anigre, anigre],
  },
  {
    category: 'Wood Boards',
    name: 'Wood Chips',
    url: 'wood-chips',
    description: {
      p1: 'Wood chips are small or mid-sized wood pieces that are formed by cutting wood slabs or logs into smaller pieces generally using a machine called a wood chipper.  The chips are used for a variety of purposes ranging from wooden pulp to biomass fuel, organic mulch, playground surfacing, bioreactor and others.',
      p2: 'To buy best quality wood chips at the lowest price in the market, you can contact Global Wood Market SA. We are a leading supplier of premium grade wood logs, timber, slabs, chips, laminated boards, plywoods and more. With over 30 years of experience in the timber industry, we are trusted for quality and credibility. We have the biggest community of timber producers, suppliers and dealers in Central Africa and can consistently deliver the best quality wood at the lowest price to your doorstep anywhere in the world.',
      p3: 'Basically, wood chip can be produced from any kind of wood of any tree. However, the quality of  chips will greatly depend on the type of lumber used. Softwoods are generally less dense and grow faster, which makes them the first choice for making chips.',
      p4: 'They are used for a variety of purposes ranging from wood pulp, mulch, playground surfacing, barbecuing, bioreactor, and fuel. Common sources for wooden chips include cedar, pine, cypress, spruce, straw, etc.',
      p5: 'If you are looking for the best place to buy premium quality chips at the lowest price online, look no further. Global Wood Market SA produces and sells wood chip in a large variety and can deliver them to your doorstep anywhere in the world. We have large-sized wood chipper machines at our mill and produce all types of chips from residues to forest, sawmill chips and others.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'If wood chips are so successful today, it is certainly not by chance.  They differ from their competitors (pine bark, clay pebbles, hessian…) by their many advantages.',
    images: [wood, wood, wood],
  },
  {
    category: 'African Round Wood Log Species',
    name: 'Birch Wood',
    url: 'birch-wood',
    description: {
      p1: 'Yellow Birch is obtained from a deciduous tree which is native to Northeastern North America and has a 65-100 ft (20-30 m) height and a 2-3 ft (.6-1.0 m) trunk diameter. The wood is moderately heavy and hard with a Janka score of 1,260 lbf and an average dried weight of 690 kg/m3. It is most commonly used for veneer and plywood. Other uses include paneling, doors, furniture, boxes, paper, turned objects, crates, and interior trim.',
      p2: 'Yellow Birch has a light reddish brown heartwood with occasional figured grain patterns such as shallow curls. The grain is straight or sometimes wavy and the texture is fine and even. Birch is a non-durable hardwood and cannot resist moisture and insects at all. It is generally easy to work with and turns, glues, and finishes well. The cost depends on the availability, but it’s generally considered an economical hardwood.',
      p3: 'Looking to purchase birch wood in bulk? Contact Global Wood Market SA for the best price and offers. We have birch timber available in various dimensions and sizes. All our wood is sustainably sourced from responsibly managed forests and plantations. We provide doorstep delivery all over the world with a guarantee of quality and satisfaction. Contact us to tell us your requirements and preferred timber size and quantity to get the best quote.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail: `Birch hardwood comes from the same family as Alder wood (Betulaceae) and belongs to the genus Betula. There are more than 30 known species of Birch. It is a rather soft hardwood which is commonly used in the paper industry and also to make toothpicks and toys. Paper Birch, Yellow Birch, River Birch, Downy Birch, Sweet Birch, Gray Birch, Alder-leaf Birch, Masur Birch, and Silver Birch are popular Birch species.
      Contact Us to buy high-quality Birchwood timber, logs and slabs online at the best price.`,
    images: [birch, birch, birch],
  },
  {
    category: 'African Sawn Timber',
    name: 'Wenge Timber',
    url: 'wenge-timber',
    description: {
      p1: 'Wenge is a heartwood species grown in Central Africa. The trees are about 60-90 ft tall with 3-4 ft trunk diameter. The timber wood color ranges from medium brown to reddish with black streaks. The light and dark areas of the same wood differ in density which makes it sand unevenly. Common uses include furniture making, musical instruments, veneer, turned objects, etc.',
      p2: 'Need the best quality Wenge Timber for your next woodwork project. Check out the Wenge Wood quality, Specification, Grade, and Measurements at Global Wood Market SA? Use the form below to send your trade enquiries, or call/Whatsapp +237695268508.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    images: [wenge, wenge, wenge],
  },
  {
    category: 'African Wood Slabs',
    name: 'Ekop Beli Slabs',
    url: 'ekop-beli-slabs',
    description: {
      p1: 'Global Wood Market SA is a leading supplier of top-grade wood slabs in all the top African hardwood species. If you are looking to buy the best quality Ekop beli wood slabs at cheap prices, Global Wood Market SA is your first and only choice. We export our Ekop beli timber and slabs to dealers and businesses all over the world, including top countries in Asia, Europe, and the Middle East. You can contact us to check the latest prices, availability and specifications of our Ekop beli wood slabs.',
      p2: 'Beli is an African native wood, which is harvested primarily in the Gabon and Cameroon regions. The beli trees have an average height of 65-100ft and a maximum trunk diameter of 1.5m. The heartwood of beli is light brown in color with darker stripes throughout, while the sapwood is pale yellow without the stripes. The hardwood is moderately durable and has decent insect resistance capabilities. Common applications include flooring, cabinetry, veneering, and interior millworks such as chair railing, crown molding, interior doors, and wood paneling and window casing.',
      p3: 'Beli slabs are generally used as an affordable alternative to Zebrawood for doors, flooring and other range of purposes. Global Wood Market SA is the best online platform to buy top-quality Ekop beli Hardwood Slabs at the lowest possible rates with doorstep delivery anywhere in the world. We have Ekop beli wood slabs cut into custom sizes and kiln dried to preserve the highest levels of quality and remove any insects from it. You can order Ekop beli slabs in whatever size and dimensions that you want. All our wood timber and slabs are made at our own mill located in Douala, Cameroon and processed with great care. We also supply the wood to our customers in multiple countries around the globe.',
      p4: 'Based on your choice, you can select from rough edge Ekop beli hardwood slabs or planed slabs. All variants are read to work with and can be used for a variety of woodworking projects.',
      p5: 'For all your Ekop beli wood slabs requirements, you can contact us through our website. For enquiries about wood species, quality, measurements and price, feel free to reach us via call or Whatsapp at +237 695 268 508 or drop an email to info@globalwoodmarketsa.com. Make sure to mention your preferred size and dimensions when placing an online order with Global Wood Market SA.',
    },
    availability: 'In Stock',
    size: null,
    file: null,
    detail:
      'Looking for the best place to buy Ekop beli wood slabs at wholesale prices? Buy today from Global Wood Market SA to get the best price guarantee on our complete range of hardwood slabs.',
    images: [ekopbeli, ekopbeli, ekopbeli],
  },
]
