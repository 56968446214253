import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import emailjs from '@emailjs/browser'
import env from '../config/env'

const useSendMail = () => {
  const [loading, setLoading] = useState(false)

  const [country, setCountry] = useState(null)
  const handleContryChange = (value) => {
    console.log(value?.value)
    setCountry(value)
  }

  const initialValues = {
    name: '',
    email: '',
    telephone: '',
    country: '',
    message: '',
  }

  const validationSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    telephone: yup.number().required(),
    country: yup.string().required(),
    message: yup.string().required(),
  })

  const onSubmit = (e) => {
    setLoading(true)
    console.log(e)
    emailjs
      .send(env.service_id, env.template_id, e, {
        publicKey: env.public_key,
      })
      .then(
        () => {
          console.log('SUCCESS!')
          alert('Message sent successfully...')
        },
        (error) => {
          console.log('FAILED...', error.text)
        }
      )
      .finally(() => {
        formik.resetForm()
        setCountry(null)
        setLoading(false)
      })
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    formik.setFieldValue('country', country === null ? '' : country?.value)
  }, [country])

  return {
    formik,
    loading,
    country,
    handleContryChange,
  }
}

export default useSendMail
