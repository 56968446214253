import Cookies from 'js-cookie'
import { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// create context
export const LangContext = createContext()

export function LangProvider({ children }) {
  const [lang, setLang] = useState('fr')
  const [windowDimension, setWindowDimension] = useState(window.innerWidth)
  const { i18n } = useTranslation()

  const handleLanguageChange = (lang) => {
    Cookies.set('i18next', lang)
    i18n.changeLanguage(lang).then((r) => setLang(lang))
  }

  useEffect(() => {
    const lang = Cookies.get('i18next')
    if (lang === 'en-US' || lang === 'en-GB') {
      setLang('en')
    } else if (lang === 'fr-FR') {
      setLang('fr')
    } else {
      setLang(Cookies.get('i18next'))
    }
    console.log('languageAAA ' + lang)
  }, [lang])

  return (
    <LangContext.Provider value={{ lang, handleLanguageChange }}>
      {children}
    </LangContext.Provider>
  )
}
