import React from 'react'
import Banner from '../../components/Banner'
import NavbarLink from '../../components/NavbarLink/NavbarLink'
import Navbar from '../../components/Navbar'
import Article from '../../components/Article/Article'
import wood from '../../assets/about/Wood-Harvesting-100x100.jpg'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { useLocation } from 'react-router-dom'
import Contact from '../../components/Contact'
import ProductDetailCard from '../../components/ProductDetailCard/ProductDetailCard'
import ProductDetailForm from '../../components/ProductDetailForm/ProductDetailForm'
import { products } from '../../fakers'
import useCountries from '../../hooks/useCountries'
import useSendMail from '../../hooks/useSendMail'
import Popups from '../../components/Popups/Popups'

const ProductDetail = () => {
  const { state } = useLocation()

  const settings = {
    // customPaging: function (i) {
    //   return (
    //     <a href="/">
    //       <img src={Image1} alt="" />
    //     </a>
    //   );
    // },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const { formik, loading, country, handleContryChange } = useSendMail()
  const { allCountries } = useCountries()

  return (
    <>
      <Banner />
      <Popups />
      <NavbarLink position='sticky top-0' />
      <Navbar
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />
      {/* content */}
      <div className='flex flex-col md:flex-row gap-2 w-full px-content flex-wrap'>
        {/* left side */}
        <div className='flex-1 py-5 space-y-3'>
          <p className='text-3xl text-primary font-bold capitalize'>
            {state?.name}
          </p>
          {/* slider */}
          <div className='w-full border  flex gap-4 items-center'>
            <img src={state?.images[2]} alt='' className='w-1/2' />
            {/* <Slider {...settings}>
                                <div>
                  <img src={Image1} alt="" />
                </div>
                <div>
                  <img src={Image2} alt="" />
                </div>
                <div>
                  <img src={Image3} alt="" />
                            </div>
            </Slider> */}
            <div className='space-y-1 text-sm'>
              <div className='flex items-center gap-1'>
                <span className='text-gray-600 font-semibold'>
                  Availability :
                </span>
                <span className='text-gray-600'>{state?.availability}</span>
              </div>
              <div className='flex items-center gap-1'>
                <span className='text-gray-600 font-semibold'>Size :</span>
                <span className='text-gray-600'>
                  {state?.size === null ? 'N/A' : state?.size}
                </span>
              </div>
              <p className='text-gray-600 leading-5'>
                {state?.detail}
                {/* Are you searching for a reputable supllier of top Ekop-Naga
                round logs in your country ? You finally reached the right
                place. Hurry Up! Place your orders from{' '}
                <a href='/' className='text-primary'>
                  Global Wood Market SA
                </a>
                . Fill in a few details in the form below and send your
                enquiries along with preferred size and measurements. */}
              </p>
              <div className='flex items-center gap-1'>
                <span className='text-gray-600'>Category :</span>
                <a
                  href={`/product-category/${state?.category}`}
                  className='hover:text-blue-500/10'
                >
                  <span className='text-gray-900 hover:text-blue-500'>
                    {state?.category}
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* description */}
          <div>
            <div className='flex flex-col items-start'>
              <span className='p-3 bg-primary text-white text-sm font-semibold'>
                Description
              </span>
              <div className='w-full border-b border-b-black border-opacity-15'></div>
            </div>
            <div className='bg-gray-300/20 px-10 pt-10 pb-20 flex flex-col gap-20'>
              <div className='space-y-3'>
                <p className='font-semibold text-sm'>Description</p>
                <p className='font-semibold'>About {state?.name}</p>
                <div className='text-gray-500 text-sm'>
                  <p className='pb-3 leading-6'>{state?.description?.p1}</p>
                  <p className='pb-3 leading-6'>{state?.description?.p2}</p>
                  <p className='pb-3 leading-6'>{state?.description?.p3}</p>
                  <p className='pb-3 leading-6'>{state?.description?.p4}</p>
                  <p className='pb-3 leading-6'>{state?.description?.p5}</p>
                  <p className='pb-3 leading-6'>{state?.description?.p6}</p>
                </div>
              </div>

              {/* form */}
              <ProductDetailForm
                allCountries={allCountries}
                formik={formik}
                loading={loading}
                country={country}
                handleContryChange={handleContryChange}
              />
            </div>

            {/* related products */}
            <div className='mt-10 space-y-3'>
              <p className='text-xl text-primary font-bold'>Related Products</p>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3'>
                {products
                  ?.filter(
                    (val) =>
                      val.category === state?.category &&
                      val.name !== state?.name
                  )
                  ?.slice(0, 4)
                  ?.map((item, ind) => (
                    <ProductDetailCard
                      key={ind}
                      name={item.name}
                      img={item.images[2]}
                      item={item}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className='flex-1 md:flex-none '>
          <Article
            title1='Anigre Wood'
            src1={wood}
            alt1='wood'
            title2='Wood Chips'
            src2={wood}
            alt2='wood'
            title3='Birch Wood'
            src3={wood}
            alt3='wood'
            title4='Wenge Timber'
            src4={wood}
            alt4='wood'
            title5='Ekop Beli Slabs'
            src5={wood}
            alt5='wood'
          />
        </div>
      </div>
      {/* contact */}
      <Contact
        allCountries={allCountries}
        formik={formik}
        loading={loading}
        country={country}
        handleContryChange={handleContryChange}
      />{' '}
    </>
  )
}

export default ProductDetail
