import React, { useEffect, useState } from 'react'

const useCountries = () => {
  const [allCountries, setAllCountries] = useState([])

  const url = 'https://restcountries.com/v3.1/all'

  const excludedContinents = ['Africa']
  const includedAfricanCountries = ['Cameroon', 'Morocco', 'Egypt', 'Angola']

  useEffect(() => {
    const getCountryNames = async () => {
      const response = await fetch(url)
      const countries = await response.json()

      // countries
      //   .filter((country) => {
      //     return (
      //       !excludedContinents.includes(country.continents[0]) ||
      //       includedAfricanCountries.includes(country.translations.fra.common)
      //     )
      //   })
      //   .map((country) => country.translations.fra.common)
      //   .sort((nom1, nom2) => nom1.localeCompare(nom2))
      // Tri par ordre alphabétique

      setAllCountries(
        countries
          ?.filter((country) => {
            return (
              !excludedContinents.includes(country.continents[0]) ||
              includedAfricanCountries.includes(country.name.common)
            )
          })
          .map((country) => country.name.common)
          .sort((nom1, nom2) => nom1.localeCompare(nom2))
          .map((item) => {
            return {
              label: item,
              value: item,
            }
          })
      )
    }

    getCountryNames()
  }, [])

  return {
    allCountries,
  }
}

export default useCountries
