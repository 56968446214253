import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './locales/lang/en.json'
import fr from './locales/lang/fr.json'

const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    debug: true,
    fallbackLng: 'fr',
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['cookie'],
    },
  })
