import React from "react";
import { NavLink } from "react-router-dom";

const TopRated = ({ title, src, alt,item }) => {
  return (
    <NavLink
      to={`/product/${title}`} state={item}
      className="flex justify-between pb-2 items-center font-bold text-[#411503] hover:text-[#337ab7]"
    >
      <span>{title}</span>
      <img src={src} alt={alt} width={30} height={30} />
    </NavLink>
  );
};

export default TopRated;
