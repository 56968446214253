import React from 'react'
import BannerTop from '../../assets/home/banner-top.gif'
import BannerMobile from '../../assets/home/banner-top-mobile.gif'

const Banner = () => {
  return (
    <div>
      <div className='hidden lg:block'>
        <img
          src={BannerTop}
          alt='BannerTop'
          className='h-12 fixed-top top-0 '
        />
      </div>
      <div className=' sm:block lg:hidden'>
        <img src={BannerMobile} alt='BannerMobile' />
      </div>
    </div>
  )
}

export default Banner
